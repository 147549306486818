import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


const data = [
    {
        'id': 1,
        'title': 'Situational Titles',
        'subtitle': 'Situational titles can help user personalise the reach to specific situaitons',
        'image': '/images/situational/situational-1.png',
        'video': null
    },
    {
        'id': 2,
        'title': 'Social Situation Enabling Posts',
        'subtitle': 'Engaging content posting for social awareness wisdom can help users & businesses each beyond their friends network to...',
        'image': '/images/situational/situational-2.png',
        'video': null
    },
    {
        'id': 3,
        'title': 'Add Image Video Filter Audio',
        'subtitle': 'Unique filters that can enable users create unique social situations',
        'image': '/images/situational/situational-3.png',
        'video': null
    },
    {
        'id': 4,
        'title': 'Explore Meaningful Content By Personalising Interests',
        'subtitle': 'Explore wide range of situational titles, social wisdom, trending posts.',
        'image': '/images/green-frame.png',
        'video': '/videos/situational/situational-2.mp4'
    }
]

export default function Features() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const autoPlayRef = useRef();

    const nextSlide = () => {
        if (currentSlide === data.length - 1) {
            return;
        }

        setCurrentSlide(currentSlide + 1)
    }

    const previousSlide = () => {
        if (currentSlide === 0) {
            return;
        }
        else if (currentSlide > 0 && currentSlide <= data.length - 1) {
            return setCurrentSlide(currentSlide - 1)
        }
    }

    // Use for autoplay
    // useEffect(() => {
    //     autoPlayRef.current = nextSlide;
    // })

    // useEffect(() => {
    //     const play = () => {
    //         autoPlayRef.current()
    //     }

    //     const interval = setInterval(play, 3000)
    //     return () => clearInterval(interval)
    // }, [])

    const subTitleCSS = {
      width: "100%",
      height:  window.innerWidth > 800 ?"440px":"auto",
      display: "flex",
      flexDirection:"column",
      padding:  window.innerWidth > 800 ?"auto":"25px 25px 50px 30px",
      alignItems: window.innerWidth > 800 ? 'flex-start':'center',
      paddingLeft:  window.innerWidth > 800 ? '60px':'0',
      justifyContent: 'center'
    };




    return (
        <div style={{
            backgroundColor: '#272727',
            width: '100vw',
            height: '100vh'
        }}>
            <div style={{ width: '100%', height: window.innerWidth > 800 ?'440px':"", display: 'flex', flexDirection:  window.innerWidth > 800 ?'row':'column' }}>
                <div style={{ ...subTitleCSS}}>
                    <Typography fontWeight="Bold" variant="h1" style={{ textAlign:  window.innerWidth > 800 ?'left':'center' }}>{data[currentSlide].title}</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign:  window.innerWidth > 800 ?'left':'center' }}>{data[currentSlide].subtitle}</Typography>
                </div>
                {
                    (data[currentSlide].video !== null) ?
                        <div style={{ position: 'relative', width: '100%', height: '440px', paddingRight: '80px', textAlign: 'center' }}>
                            <video src={data[currentSlide].video} width={220} height={410} autoPlay loop muted
                                style={{ position: 'absolute', top: '10px', left: '44%' }}
                            />
                            <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                                style={{ position: 'absolute', top: 0 }}
                            />
                        </div>
                        :
                        <div style={{ width: '100%', height: '440px', textAlign: 'center' }}>
                            <img src={data[currentSlide].image} width="260" height="440" alt="images" />
                        </div>
                }
            </div>
            {/* Slide Indicators */}
            <div
                style={{
                    width: window.innerWidth,
                    paddingTop: '60px',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div onClick={previousSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === 0) ? <div /> : <ArrowCircleLeftOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                        key={1}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 0 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(0); }}
                    />
                    <button
                        key={2}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 1 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(1); }}
                    />
                    <button
                        key={3}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 2 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(2); }}
                    />
                    <button
                        key={4}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 3 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(3); }}
                    />
                </div>
                <div onClick={nextSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === data.length - 1) ? <div /> : <ArrowCircleRightOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
            </div>
        </div>
    );
}