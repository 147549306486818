import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Layout from "../layout";
import {
  valuesPannel,
  valuesContent,
  valuesImage,
  marginBottom70,
  marginBottom10,
} from "../../common/ComponentStyle";

export default function OurValue() {
  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: window.innerWidth,
          height: "80vh",
        }}
      >
        <img
          src="/images/values/placement-area.png"
          width={window.innerWidth}
          height={window.innerHeight * 0.8}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <Typography
          fontWeight="Bold"
          variant="h1"
          style={{
            position: "absolute",
            top: "50%",
            left: "10%",
            fontSize: "44px",
            textAlign: "left",
          }}
        >
          Our Values
        </Typography>
      </div>
      <div
        style={{
          width: window.innerWidth,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "100px",
        }}
      >
        {/* Item-1 */}
        <div style={valuesPannel}>
          <img src="/images/values/Feature-1.jpg" style={{...valuesImage,...marginBottom10}} />
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{
              ...valuesContent,
              opacity: window.innerWidth > 800 ? 0 : 1,
            }}
          >
            We value individual choices
          </Typography>
        </div>
        {/* Item-2 */}
        <div style={valuesPannel}>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{
              ...valuesContent,
              opacity: window.innerWidth > 800 ? 1 : 0,
            }}
          >
            We value individual choices
          </Typography>
          <img src="/images/values/Feature-2.jpg" style={{...valuesImage,...marginBottom10}} />
          {window.innerWidth < 800 && (
            <Typography
              fontWeight="Normal"
              variant="body2"
              style={{
                ...valuesContent,
                opacity: window.innerWidth > 800 ? 0 : 1,
                ...marginBottom70,
              }}
            >
              We value privacy of users
            </Typography>
          )}
        </div>
        {/* Item-3 */}
        <div style={valuesPannel}>
          <img src="/images/values/Feature-3.jpg" style={{...valuesImage,...marginBottom10}} />
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ ...valuesContent }}
          >
            {" "}
            {window.innerWidth > 800
              ? "We value privacy of users"
              : "Technology to be human-centric"}
          </Typography>
        </div>
        {/* Item-4 */}
        <div style={valuesPannel}>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{
              ...valuesContent,
              opacity: window.innerWidth < 800 ? 0 : 1,
            }}
          >
            Technology to be human-centric
          </Typography>
          <img src="/images/values/Feature-4.jpg" style={{...valuesImage,...marginBottom10}} />
          {window.innerWidth < 800 && (
            <Typography
              fontWeight="Normal"
              variant="body2"
              style={{ ...valuesContent, ...marginBottom70 }}
            >
              Importance of co-existance
            </Typography>
          )}
        </div>
        {/* Item-5 */}
        <div style={valuesPannel}>
          <img src="/images/values/Feature-5.jpg" style={{...valuesImage,...marginBottom10}} />
          {window.innerWidth > 800 && (
            <Typography
              fontWeight="Normal"
              variant="body2"
              style={valuesContent}
            >
              Importance of co-existance
            </Typography>
          )}
        </div>
        {/* Item-6 */}
        <div style={valuesPannel}>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ ...valuesContent, ...marginBottom70 }}
          >
            Contributing to society for personal well-being
          </Typography>
          <img src="/images/values/Feature-6.jpg" style={{...valuesImage,...marginBottom10}} />
        </div>
        {/* Item-7 */}
        <div style={valuesPannel}>
          {window.innerWidth < 800 && (
            <Typography
              fontWeight="Normal"
              variant="body2"
              style={{ ...valuesContent, ...marginBottom70 }}
            >
              Understand the value of impact rather than influence
            </Typography>
          )}
          <img src="/images/values/Feature-7.jpg" style={{...valuesImage,...marginBottom10}} />
          {window.innerWidth > 800 && (
            <Typography
              fontWeight="Normal"
              variant="body2"
              style={valuesContent}
            >
              Understand the value of impact rather than influence
            </Typography>
          )}
        </div>
        {/* Item-8 */}
        <div style={valuesPannel}>
          <Typography fontWeight="Normal" variant="body2" style={valuesContent}>
            Learning and knowledge system needs to update
          </Typography>
          <img
            src="/images/values/Feature-7.jpg"
            style={{ ...valuesImage, opacity: 0 }}
          />
        </div>
      </div>
    </Layout>
  );
}
