import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "./layout";
import './home.css';
import {
  homeDesktopCss,
  homeMobiletitlecss,
  homeVedioCss,
} from "../common/ComponentStyle";


const homeSlides = [
    {
        id: 1,
        imageUrl: '/images/mixkit-network-of-luminous-particles-in-space.jpg',
        description: 'Worlds first Social \nSituational \nAwareness App to \nAdvance Human \nConsciousness'
    },
    {
        id: 2,
        imageUrl: '/images/mixkit-network-of-luminous-particles-in-space.jpg',
        description: 'Social wisdom for \nWell Being'
    },
    {
        id: 3,
        imageUrl: '/images/mixkit-network-of-luminous-particles-in-space.jpg',
        description: 'Every thought \ncounts. \nExpress to enrich \nlife situations'
    }
]

export default function Home() {
    const [infoIndex, setInfoIndex] = useState(0);
    const autoPlayRef = useRef();

    const nextSlide = () => {
        if (infoIndex === homeSlides.length - 1) {
            return setInfoIndex(0)
        }

        setInfoIndex(infoIndex + 1)
    }

    useEffect(() => {
        autoPlayRef.current = nextSlide;
    })

    useEffect(() => {
        const play = () => {
            autoPlayRef.current()
        }

        const interval = setInterval(play, 3000)
        return () => clearInterval(interval)
    }, [])

    return (
        <Layout>
          <video
                id={'home'}
                loop
                autoPlay
                muted
                playsInline
                poster="/images/mixkit-network-of-luminous-particles-in-space.jpg"
                src="/videos/luminous-particles-in-space.mp4"
                width={window.innerWidth}
                height={window.innerHeight}
                style={homeVedioCss}
            />
            <div style={window.innerWidth>800 ? homeDesktopCss:homeMobiletitlecss}>
                <div style={{ paddingLeft: '10%', paddingRight: '10%', display: 'flex', flexDirection:  window.innerWidth < 800 ? "column" : "row", justifyContent: 'center', alignItems: 'center',gap: window.innerWidth < 800 ? "20px" : "auto",  height:  window.innerWidth < 800 ?"450px":"auto" }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ flex: 1, textAlign: window.innerWidth < 800 ?'center':'left',fontSize:window.innerWidth < 800 ?"25px":"",lineHeight:window.innerWidth < 800 ?'45px':'' }}>{homeSlides[infoIndex].description}</Typography>
                    <img src={"/images/home-logo.png"} width="100%"  alt="cheers home logo" style={{ flex: 1, paddingLeft: '10px', objectFit:"contain" }} />
                </div>
                {/* Download App */}
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: window.innerWidth>800?'row':'column',
                        paddingTop: '40px',
                        paddingLeft:  window.innerWidth>800?'10%':'',
                        alignItems: 'center'
                    }}
                >
                    <Typography fontWeight="Bold" variant="body2" style={{ textAlign: 'left' }}>Download our App</Typography>
                    <div style={{flexDirection: window.innerWidth>800?'':'row'}}>
                    <img src={"/images/google-play.png"} width="36" height="36" alt="cheers playstore" style={{ paddingLeft: '10px', paddingRight: '10px' }} />
                    <img src={"/images/appstore.png"} width="36" height="36" alt="cheers appstore" />
                    </div>
                </div>
                {/* Slide Indicators */}

                <div
                    style={{
                        width: window.innerWidth,
                        paddingTop: '60px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <button
                        key={1}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${infoIndex === 0 ? '#91FFB9' : '#272727CC'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setInfoIndex(0); }}
                    />
                    <button
                        key={2}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${infoIndex === 1 ? '#91FFB9' : '#272727CC'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setInfoIndex(1); }}
                    />
                    <button
                        key={3}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${infoIndex === 2 ? '#91FFB9' : '#272727CC'}`, borderRadius: '8px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setInfoIndex(2); }}
                    />
                </div>
                <hr/>
                <span className="collaborator-txt">Our collaborators:</span>
<div style={{ backgroundColor: "rgb(39 38 38)" }}>
  <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: "20px" }}>
    <img src={"images/about/faubert-new.png"} width="140" height="auto" alt="NIMS Jaipur" />
      <a href="http://faubertlab.com/en/home/" style={{ color: '#fff', textAlign: 'center' }} target="_blank"><b>Faubert Lab, Montreal</b></a>

    </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

    <img src={"images/about/logo-nims.svg"} width="130" height="auto" alt="NIMS Jaipur" />
      <a href="https://www.nimsuniversity.org/"  className="wrap-colabrator-title" style={{ color: '#fff', textAlign: 'center', wordWrap:"break-word", width:"70%" }} target="_blank"><b>National Institute of Medical Sciences, Jaipur</b></a>
    </div>
  </div>
</div>
            </div>
            <div style={{
                width: window.innerWidth>800? window.innerWidth:"auto",
                height: window.innerWidth>800?'100vh':"auto",
                backgroundColor: '#272727CC'
            }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        style={{height:window.innerWidth>800?'100vh':"auto", padding: window.innerWidth>800?"":"200px 0 50px 0px"}}
                    >
                               <Grid
                            item
                            xs={12} md={4} lg={4}
                            width={'100%'}
                            px={'20px'}
                            display="flex"
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography fontWeight="SemiBold" variant="body2" style={{ width: '352px', height: '100px', textAlign: 'start', fontSize: window.innerWidth>800?'18px':'25px',padding:window.innerWidth>800?"":"80px 0px 50px 0px" }}>Understanding the situations and cognitive functions</Typography>
                            <div style={{ width: '352px', height: '288px' }}>
                                <img className="imageScale" src={"/images/placement-1.jpg"} width="352" height="288" alt="placement1" style={{}} />
                            </div>
                            <Link component={RouterLink} to='/concept' underline="none" fontWeight="Normal" variant="body2" style={{ width: '352px', color: '#91FFB9', textAlign: 'start', marginTop: '10px', fontSize: window.innerWidth>800?'':'25px'}}>
                                CONCEPT
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12} md={4} lg={4}
                            width={'100%'}
                            px={'20px'}
                            display="flex"
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography fontWeight="SemiBold" variant="body2" style={{ width: window.innerWidth<800?"auto":'352px', height: window.innerWidth>800?"auto":'100px',textAlign: 'start', fontSize: window.innerWidth>800?'18px':'25px',padding:window.innerWidth>800?"":"80px 0px 50px 0px" }}>State of art technology to communicate situational awareness through explainable AI</Typography>
                            {/* <img src={"/images/placement-2.jpg"} width={window.innerWidth>800?"352":"100%"} height={window.innerWidth>800?"352":"auto"}  alt="placement1" style={{}} /> */}
                            <div style={{ width:window.innerWidth>800 ? '352px':"100%", height: window.innerWidth>800 ?'288px':"auto"}}>
                                <img className="imageScale" src={"/images/placement-2.jpg"} width={window.innerWidth>800?"352":"100%"} height={window.innerWidth>800?"288":"auto"}  alt="placement2" style={{}} />
                            </div>
                            <Link component={RouterLink} to='/method' underline="none"  fontWeight="Normal" variant="body2" style={{ width: window.innerWidth>800?'352px':"100%", textAlign: 'start', marginTop: '10px',color: '#91FFB9',fontSize: window.innerWidth>800?'':'25px' }}>
                                METHOD
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={12} md={4} lg={4}
                            width={'100%'}
                            px={'20px'}
                            display="flex"
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography fontWeight="SemiBold" variant="body2" style={{ width: window.innerWidth<800?"auto":'352px', height: window.innerWidth>800?'100px':"auto",textAlign: 'start', fontSize: window.innerWidth>800?'18px':'25px',padding:window.innerWidth>800?"":"80px 0px 50px 0px" }}>Transferring power to users to handle everyday situations</Typography>
                            {/* <img src={"/images/placement-3.jpg"} width="352px" height="288px" alt="placement1" style={{}} /> */}
                            <div style={{ width:window.innerWidth>800 ? '352px':"100%", height: window.innerWidth>800 ?'288px':"auto"}}>
                                <img className="imageScale" src={"/images/placement-3.jpg"} width={window.innerWidth>800?"352":"100%"} height={window.innerWidth>800?"288":"auto"}  alt="placement3" style={{}} />
                            </div>
                            <Link component={RouterLink} to='/use-case' underline="none" fontWeight="Normal" variant="body2" style={{ width: window.innerWidth>800?'352px':"100%", textAlign: 'start', marginTop: '10px',color: '#91FFB9',fontSize: window.innerWidth>800?'':'25px' }}>
                                USECASE
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            {/* Section-2 */}
            <div style={{
                width: '100%',
                height:  window.innerWidth<800 ?'75vh' :'100vh',
                backgroundColor: '#272727',
                opacity: 1,
            }}>
                {window.innerWidth<800 && <div style={{ position: 'absolute', width: '87%', height: '40%', zIndex: '2', padding:'90px 25px 0px' }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ textAlign: 'start', fontSize: '44px', lineHeight: '54px', marginBottom: '10px' }}>Create the <br />Future with us</Typography>
                    <hr style={{ height: '0.5px', border: 'none', color: '#fff', backgroundColor: '#fff' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ textAlign: 'start', fontSize: '14px', marginTop: '10px' }}>Our endeavour to develop social situational awareness <br />technologies can impact the world limitless applications. <br />With passionate teams and resources, we can build a <br />society that achieve well being.</Typography>
                </div>}

                <img src={window.innerWidth>800 ? "/images/mask-group.png":"/images/mask-group-mobile.png"} width="100%" height={window.innerWidth>800  ?"100%":"55%"} alt="mask group" style={{ position: 'absolute', zIndex: window.innerWidth<800  ? '1': '' }} />
               {window.innerWidth>800 && <div style={{ position: 'relative', width: '50%', height: '50%', top: '30%', left: '15%' }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ textAlign: 'start', fontSize: '44px', lineHeight: '54px', marginBottom: '10px' }}>Create the <br />Future with us</Typography>
                    <hr style={{ height: '0.5px', border: 'none', color: '#fff', backgroundColor: '#fff' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ textAlign: 'start', fontSize: '14px', marginTop: '10px' }}>Our endeavour to develop social situational awareness <br />technologies can impact the world limitless applications. <br />With passionate teams and resources, we can build a <br />society that achieve well being.</Typography>
                </div>}
            </div>
        </Layout >
    );
}