import React from "react";
import NavBar from './nav';
import Footer from "./footer";

export default function Layout({ children }) {
    return (
        <div 
        className="ine-container"
        style={{
            // width: window.innerWidth,
            height: '100%',
            backgroundColor: '#272727',
            overflow: 'hidden'
        }}>
            <NavBar />
            {children}
            <Footer />
        </div>
    );
}