import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


const data = [
    {
        'id': 1,
        'title': 'Clubbies',
        'titleRightIcon': null,
        'subtitle': 'Matches for every posts with people of similar intentions',
        'image': null,
        'video': '/videos/clubbies/clubbies.mp4'
    },
    {
        'id': 2,
        'title': 'Hifi',
        'titleRightIcon': '/images/clubbies/hifi-icon.png',
        'subtitle': 'Hifi represents the interest to connect with a person of similar situational interests',
        'image': null,
        'video': '/videos/clubbies/hifi.mp4'
    },
    {
        'id': 3,
        'title': 'Filters',
        'titleRightIcon': null,
        'subtitle': 'Use filters to match with specific age, gender and location',
        'image': null,
        'video': '/videos/clubbies/c_filters.mp4'
    },
    {
        'id': 4,
        'title': 'Find Potential Influencers For Brand',
        'titleRightIcon': null,
        'subtitle': '',
        'image': null,
        'video': '/videos/clubbies/clubbies-1.mp4'
    }
]

export default function Features() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const autoPlayRef = useRef();

    const nextSlide = () => {
        if (currentSlide === data.length - 1) {
            return;
        }

        setCurrentSlide(currentSlide + 1)
    }

    const previousSlide = () => {
        if (currentSlide === 0) {
            return;
        }
        else if (currentSlide > 0 && currentSlide <= data.length - 1) {
            return setCurrentSlide(currentSlide - 1)
        }
    }

    // Use for autoplay
    // useEffect(() => {
    //     autoPlayRef.current = nextSlide;
    // })

    // useEffect(() => {
    //     const play = () => {
    //         autoPlayRef.current()
    //     }

    //     const interval = setInterval(play, 3000)
    //     return () => clearInterval(interval)
    // }, [])

    const subTitleCSS = {
        width: "100%",
        height:  window.innerWidth > 800 ?"440px":"auto",
        display: "flex",
        flexDirection:"column",
        padding:  window.innerWidth > 800 ?"auto":"25px 25px 50px 30px",
        alignItems: window.innerWidth > 800 ? 'flex-start':'center',
        paddingLeft:  window.innerWidth > 800 ? '60px':'0',
        justifyContent: 'center'
      };
  


    return (
        <div style={{
            backgroundColor: '#272727',
            width: '100vw',
            height: '100vh'
        }}>
            <div style={{ width: '100%', height: window.innerWidth > 800 ?'440px':"", display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column' }}>
                <div style={{ ...subTitleCSS }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'start', alignItems:  window.innerWidth > 800 ?'left':'center', padding:  window.innerWidth > 800 ?'0':'10px 25px 10px 15px'}}>
                        <Typography fontWeight="Bold" variant="h1" style={{ textAlign:  window.innerWidth < 800 ?'left':'center' }}>{data[currentSlide].title}</Typography>
                        {
                            (data[currentSlide].titleRightIcon !== null)
                                ?
                                <img src={data[currentSlide].titleRightIcon} width="40" height="26" style={{ paddingLeft: '5px' }} alt="hihi icon" />
                                :
                                <div />
                        }
                    </div>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign:  window.innerWidth > 800 ?'left':'center' }}>{data[currentSlide].subtitle}</Typography>
                </div>
                {
                    (data[currentSlide].video !== null) ?
                        <div style={{ position: 'relative', width: '100%', height: '440px', paddingRight: '80px', textAlign: 'center',right: window.innerWidth < 800 ?"30%":"" }}>
                            <video src={data[currentSlide].video} width={220} height={410} autoPlay loop muted
                                style={{ position: 'absolute', top: '10px', left: '44%' }}
                            />
                            <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                                style={{ position: 'absolute', top: 0 }}
                            />
                        </div>
                        :
                        <div style={{ width: '100%', height: '440px', textAlign: 'center' }}>
                            <img src={data[currentSlide].image} width="260" height="440" alt="images" />
                        </div>
                }
            </div>
            {/* Slide Indicators */}
            <div
                style={{
                    width: window.innerWidth,
                    paddingTop: '60px',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div onClick={previousSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === 0) ? <div /> : <ArrowCircleLeftOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                        key={1}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 0 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(0); }}
                    />
                    <button
                        key={2}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 1 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(1); }}
                    />
                    <button
                        key={3}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 2 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(2); }}
                    />
                    <button
                        key={4}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 3 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(3); }}
                    />
                </div>
                <div onClick={nextSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === data.length - 1) ? <div /> : <ArrowCircleRightOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
            </div>
        </div>
    );
}