import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import Layout from "../layout";

const requestType = {
    10: 'Careers',
    20: 'Brand Partnership',
    30: 'Investments',
    40: 'Others'
}

export default function FindUs() {
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [option, setOption] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fullNameChange = event => {
        setFullname(event.target.value);
    };

    const emailChange = event => {
        setEmail(event.target.value);
    };

    const contactChange = event => {
        setContact(event.target.value);
    };

    const messageChange = event => {
        setMessage(event.target.value);
    };

    function handleChange(event) {
        setOption(event.target.value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log('contact: ', contact);
        console.log('fullname: ', fullname);
        console.log('email type: ', requestType[option]);
        if (fullname == '' || fullname == undefined) {
            setError('Fullname is mandatory');
            return
        }
        else if (email == '' || email == undefined) {
            setError('Email is mandatory');
            return
        }
        else if (contact == '' || contact == undefined) {
            setError('Contact number is mandatory');
            return
        }
        else if (option == 1 || option == undefined) {
            setError('Please select the appropriate option');
            return
        }
        else {
            setIsLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append('full_name', fullname);
            bodyFormData.append('from_email', email);
            bodyFormData.append('mobile', contact);
            bodyFormData.append('body', message);
            bodyFormData.append('email_type', requestType[option]);
            bodyFormData.append('channel', 'web');
            if (file != null || file != undefined) {
                bodyFormData.append('file', file);
            }

            axios({
                method: "post",
                url: "https://q5qeszfjbg.execute-api.us-west-2.amazonaws.com/dev/api/v1/email/sendEmail/",
                data: bodyFormData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                },
            })
                .then(function (response) {
                    //handle success
                    console.log('success: ', response);
                    setIsOpen(true);
                })
                .catch(function (response) {
                    //handle error
                    console.log('error: ', response);
                    setError(response)
                });
            setIsLoading(false);
        }

        // alert('A name was submitted: ' + option.toString() + fullname + contact.toString());

    }

    function onFileChange(event) {
        console.log('filename count: ', event.target.files.length)
        setFile(event.target.files[0])
        console.log('filename: ', event.target.files[0].name)
    }

    function onResetUpload(event) {
        setFile(null);
    }

    function openDialog() {
        setIsLoading(true);
        // Validate the input fields
        const firstName = firstName;

        setIsLoading(false);
        setIsOpen(true);

    }

    function closeDialog() {
        setIsOpen(false);
    }

    return (
        <Layout>
            <div style={{
                width: window.innerWidth,
                paddingTop: window.innerWidth> 800 ?"8%":"20%",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '36ch' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& label': {
                            color: 'white'
                        },
                        '& label.Mui-focused': {
                            color: 'white',
                        },
                        '& .MuiInputBase-colorPrimary': {
                            color: 'white'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'white',
                                borderWidth: '0.8px'
                            },
                            '&:hover fieldset': {
                                borderColor: 'green',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'green',
                            },
                        },
                        '& .MuiSelect-select': {
                            color: 'white'
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'green'
                        },
                        '& .MuiButton-outlined': {
                            borderColor: 'green',
                        },
                        '& .MuiButton-outlined:hover': {
                            borderColor: 'gray',
                        },
                        '& .MuiButton-root': {
                            color: 'green'
                        },
                        '& .MuiButton-root:hover': {
                            color: 'gray'
                        }
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '32px', textAlign: 'center' }}>Find Us</Typography>
                    <TextField onChange={fullNameChange} id="outlined-basic" label="Full Name" variant="outlined" required />
                    <TextField onChange={emailChange} id="outlined-basic" label="Email Address" variant="outlined" required />
                    <TextField onChange={contactChange} id="outlined-basic" label="Contact Number" variant="outlined" required inputMode='tel' />
                    <FormControl fullWidth>
                        <Select
                            id="demo-simple-select"
                            value={option}
                            onChange={handleChange}
                        >
                            <MenuItem disabled value={1}>
                                <em>Select Option Below*</em>
                            </MenuItem>
                            <MenuItem value={10}>Careers</MenuItem>
                            <MenuItem value={20}>Brand Partnership</MenuItem>
                            <MenuItem value={30}>Investments</MenuItem>
                            <MenuItem value={40}>Others</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField onChange={messageChange} id="outlined-basic" label="Write your message here" variant="outlined" multiline minRows={4} />
                    {
                        (file !== null)
                            ?
                            <div style={{ width: '36ch', height: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography fontWeight="Normal" variant="body2" style={{ flex: 5, fontSize: '14px', textAlign: 'start' }}>
                                    {file.name} <span style={{ fontSize: '12px' }}>selected</span>
                                </Typography>
                                <Button onClick={onResetUpload} disableElevation disableRipple variant='contained' size='small' style={{ flex: 1, backgroundColor: 'transparent', borderWidth: '0', borderColor: 'transparent', textAlign: 'end' }}>
                                    <CloseOutlinedIcon style={{ color: 'red', textAlign: 'end' }} />
                                </Button>
                            </div>
                            :
                            <label htmlFor="contained-button-file">
                                <Input onChange={onFileChange} id="contained-button-file" type="file" style={{ display: 'none' }} />
                                <Button variant="outlined" component="span" size='large' style={{ width: '100%', borderColor: 'white', borderWidth: '0.5px' }}>
                                    Upload
                                </Button>
                            </label>
                    }
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', color: 'red', textAlign: 'center' }}>{error ?? ''}</Typography>
                    {
                        (isLoading)
                            ?
                            <CircularProgress disableShrink style={{ color: 'green' }} />
                            :
                            <Button onClick={handleSubmit} id="outlined-basic" variant="outlined" size='large'>Send</Button>
                    }
                </Box>
                <Dialog
                    fullScreen={fullScreen}
                    open={isOpen}
                    onClose={closeDialog}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Success"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            We have received your request. Our support team will contact you shortly.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button> */}
                        <Button onClick={closeDialog} autoFocus>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
}