
// Home Tab css

const homeMobiletitlecss = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 1,
  paddingTop: '100px',
  gap:'20px'
}


const homeDesktopCss = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingTop: '5%'
}

const homeVedioCss = {
  position: window.innerWidth > 800 ? "relative" : "absolute",
  overflow: "hidden",
  objectFit: "cover",
};






const descriptionIcon = {
  width: "352px",
  height: "100px",
  textAlign: "start",
  fontSize: "18px",
};

const screenWidth ={
    with: window.innerWidth,
    '@media (max-width: 768px)': {
      with: "500px",
    },
    '@media (max-width: 480px)': {
      with: '400px',
    }, 
}

 const layoutStyle = {
    width: window.innerWidth,
    height: '100%',
    backgroundColor: '#272727',
    overflow: 'hidden',

    with: window.innerWidth,
    '@media (max-width: 768px)': {
      with: "500px",
    },
    '@media (max-width: 480px)': {
      with: '400px',
    }, 
}

// Values css
const valuesPannel = {
  width:  window.innerWidth >800? "80%":"auto",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: window.innerWidth >800?"row":"column",

};

const valuesImage = {
  width: "350px",
  height: window.innerWidth > 800 ? "280px" : "auto",
};

const valuesContent = {
  width: "350px",
  height: window.innerWidth > 800 ? "350px" : "auto",
  fontSize: "28px",
  textAlign: "left",
};


const marginBottom70 = {
  marginBottom:window.innerWidth > 800 ? "0" : "70px",
}

const marginBottom10 = {
  marginBottom:window.innerWidth > 800 ? "0" : "10px",
}



// Footer

const productTitleCss = {
  marginTop: window.innerWidth <800? "20px":"auto",
  fontSize: "20px",
  textAlign: "center",
  marginBottom: "14px",
  width: window.innerWidth< 800 ?"100%":"auto",


};

const productSubTitleCss = {
  color: "white",
  fontSize: "14px",
  textAlign: "center",
  marginBottom: "10px",
  width: window.innerWidth< 800 ?"100%":"auto",


}

const footerTitle = {
  width: window.innerWidth> 800 ?"100%":"auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

const methodTitleText = {
  fontSize:  window.innerWidth> 800 ?"44px":"30px",
  textAlign: "left",
  lineHeight:  window.innerWidth> 800 ?"64px":"40px",
};

const methodsCSS = {
  width:  window.innerWidth> 800 ?"60%":"80%",
  paddingRight: "0%",
  position: "absolute",
  top:  window.innerWidth> 800 ?"30%":"20%",
  left: "10%",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
};

const methhodStyle = {
  width: "100%",
  marginTop:  window.innerWidth < 800 ? "50px" : "200px",
  display: "flex",
  flexDirection: window.innerWidth < 800 ? "column" : "row",
  justifyContent: "center",
};


const methodAubStyle = {
  width: window.innerWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop:  window.innerWidth < 800 ? "50px" : "200px",
}

// usecase style

const usecaseStyle = {
  width: "100%",
  marginTop: window.innerWidth < 800 ? "50px" : "200px",
  display: "flex",
  flexDirection:  window.innerWidth < 800 ? "column" : "row",
  justifyContent: "center",
};



const usecasHeadingStyle = {
  width: window.innerWidth < 800 ? "auto" : "440px",
  height: window.innerWidth < 800 ? "auto" :"300px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "flex-start",
  padding: "25px 30px 5px 25px"
};


const usecaseImageStyle= {
  width: window.innerWidth < 800 ? "auto" :'352px',
  height: window.innerWidth < 800 ? "auto" :'288px',
  padding: window.innerWidth < 800 ? "25px" :"auto"
}

const aboutTtitleStyle = {
  width:  window.innerWidth < 800 ? "auto" :window.innerWidth,
  height:  window.innerWidth < 800 ? "auto" :'100vh',
  display: 'flex',
  flexDirection:  window.innerWidth< 800 ?"column":"row",
  justifyContent: 'center',
  alignItems: 'center'
}




export {
  descriptionIcon,
  screenWidth,
  layoutStyle,
  productTitleCss,
  productSubTitleCss,
  footerTitle,
  valuesPannel,
  valuesContent,
  valuesImage,
  marginBottom70,
  marginBottom10,
  methodTitleText,
  methodsCSS,
  methhodStyle,
  methodAubStyle,
  usecaseStyle,
  usecasHeadingStyle,
  usecaseImageStyle,
  aboutTtitleStyle,
  homeDesktopCss,
homeMobiletitlecss,
homeVedioCss
};