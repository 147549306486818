import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import Features from './features';
import ListContent from './list_content';

export default function Kitty() {
   const topStyle = {
    position: 'relative',
    top: '15%',
    alignItems: 'center',
    padding: '0px 30px 35px 30px'
   }

   const titleStyle = {
    fontSize: '28px',
    paddingLeft: '7%',
    paddingRight: '7%',
    textAlign: 'center',
    paddingTop: '15%'
   }

    return (
        <Layout>
            <Grid
                container
                width={window.innerWidth}
                height={'100vh'}
                style={{ backgroundColor: '#272727' }}
            >
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    pl={'12%'}
                    // pr={'4%'}
                    width={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    style={window.innerWidth <800? topStyle:{}}
                >
                    <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '44px', textAlign: 'left' }}>kitty</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', textAlign: window.innerWidth <800?'center':'left' }}>Worlds first situational awareness engine for all humans.</Typography>
                    <a href="#kittyDesc" style={{ textDecoration: 'none' }}><Typography fontWeight="Normal" variant="body2" style={{ textAlign: 'left', paddingTop: '10px', color: '#91FFB9' }}>LEARN MORE</Typography></a>
                </Grid>
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    width={'100%'}
                    height={'100vh'}
                    position="relative"
                    textAlign='center'
                >
                    <video src="/videos/kitty/kitty-1.mp4" width={220} height={420} autoPlay loop muted
                        style={{ position: 'absolute', top: '22%', left: window.innerWidth <800? '20%':'25%' }}
                    />
                    <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                        style={{ position: 'absolute', top: '20%', left: window.innerWidth <800? '20%':'25%' }}
                    />
                </Grid>
            </Grid>
            <div id="kittyDesc" style={{ backgroundColor: '#272727', width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography fontWeight="Bold" variant="body2" style={{...titleStyle,fontWeight : window.innerWidth>800?'':'400'}}>Our aim is to develop a dynamic awareness engine that can help every individual, professional and organization on planet achieve well being through social wisdom.</Typography>
            </div>
            <div id="kittyDesc2" style={{ backgroundColor: '#272727', width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography fontWeight="Normal" variant="body2" style={{  fontStyle: 'italic', ...titleStyle}}>Cheers to Cheers core team that made the first version. Hoping to reach every person to enable possible situations.</Typography>
            </div>
            {/* Features */}
            <Features />
            {/* List content */}
            <ListContent />
        </Layout>
    );
}