import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        // allVariants: {

        // },
        fontFamily: 'Montserrat',
        //     [
        //     'Montserrat',
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     '"Apple Color Emoji"',
        //     '"Segoe UI Emoji"',
        //     '"Segoe UI Symbol"',
        // ].join(','),
        Light: 200,
        Normal: 400,
        Medium: 500,
        SemiBold: 600,
        Bold: 700,
        ExtraBold: 900,
        LightItalic: 200,
        NormalItalic: 400,
        MediumItalic: 500,
        SemiBoldItalic: 600,
        BoldItalic: 700,
        ExtraBoldItalic: 900,
        h1: {
            color: 'white',
            fontSize: '34px',
            lineHeight: '50px'
        },
        body2: {
            color: 'white',
            fontSize: '18px'
        }
    },
    components: {
        // MuiCssBaseline: {
        //     styleOverrides: `
        //       @font-face {
        //         font-family: 'Montserrat';
        //         font-style: normal;
        //         font-display: swap;
        //         font-weight: 400;
        //         src: local('Raleway'), local('Raleway-Regular'), url(${RalewayWoff2}) format('woff2');
        //         unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        //       }
        //     `,
        // },
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: false, // No more ripple!
            },
        },
    },
});