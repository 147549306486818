import React from "react";
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import {
  methodTitleText,
  methodsCSS,
  methhodStyle,
  methodAubStyle,
  usecasHeadingStyle,
  usecaseImageStyle
} from "../../common/ComponentStyle";

export default function Method() {

    const enableUserStyles = (
        <div style={usecasHeadingStyle}>
        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left', paddingLeft:window.innerWidth<800? 'auto':'60px' }}>Enabling Users</Typography>
        {window.innerWidth> 800 && <hr style={{ width: '100%' }} />}
        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft:window.innerWidth<800? 'auto':'60px' }}>
            <p>
                Our approach to improve personal, professional and social wellbeing of users through precise situational wisdom can help users personalise their goals.
            </p>
        </Typography>
    </div>
    )


    return (
        <Layout>
            <video
                id={'method'}
                loop
                autoPlay
                muted
                poster="/images/mixkit-network-of-luminous-particles-in-space.jpg"
                src="/videos/method/placement-area.mp4"
                width={window.innerWidth}
                height={window.innerHeight}
                style={{ position: 'relative', overflow: 'hidden', objectFit: 'cover' }}
            />
            <div style={methodsCSS}>
                <Typography fontWeight="Bold" variant="h1" style={methodTitleText}>State of art techniques and engineering to capture intentions and suggest multi dimensional perspectives to to handle life situations effectively</Typography>
                <hr style={{ width: '100%', textAlign: 'left', marginTop: '20px' }} />
                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingTop: '20px' }}>Situational Wisdom engine to share situations and seek social wisdom through explainable AI</Typography>
            </div>
            <div style={methodAubStyle}>
                {/* Item-1 */}
                <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'center' }}>
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left' }}>Situation Blackbox</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingRight: '60px' }}>
                            <p>
                                Our cross domain team has built a unique situation awareness engine to capture intentions and situations of users and to provide multi dimensional perspectives of crowd wisdom.
                            </p>
                        </Typography>
                    </div>
                    <img className="hover-scale" src="/images/method/Feature-1.png" style={usecaseImageStyle} />
                </div>
                {/* Item-2 */}
                <div style={methhodStyle}>
                    { window.innerWidth< 800 && enableUserStyles}
                    <img className="hover-scale" src="/images/method/Feature-2.png" style={usecaseImageStyle} />
                    { window.innerWidth> 800 && enableUserStyles}
                </div>
            </div>
        </Layout>
    );
}