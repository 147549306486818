import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import { usecaseStyle,usecasHeadingStyle,usecaseImageStyle } from "../../common/ComponentStyle";

export default function UseCase() {
    return (
      <Layout>
        <video
          id={"usecase"}
          loop
          autoPlay
          muted
          // poster="/images/mixkit-network-of-luminous-particles-in-space.jpg"
          src="/videos/concept/placement-area.mp4"
          width={window.innerWidth}
          height={window.innerHeight}
          style={{
            position: "relative",
            overflow: "hidden",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            width: "60%",
            paddingRight: "0%",
            position: "absolute",
            top: window.innerWidth < 800 ? "25%" : "35%",
            left: "10%",
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            fontWeight="Bold"
            variant="h1"
            style={{ fontSize: "44px", textAlign: "left", lineHeight: "64px" }}
          >
            Transferring power to users to handle everyday situations
          </Typography>
          <hr style={{ width: "100%", textAlign: "left", marginTop: "20px" }} />
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "12px", textAlign: "left", paddingTop: "20px" }}
          >
            Working towards improving wellbeing of users through social wisdom
            and crowd engagement
          </Typography>
        </div>
        <div
          style={{
            width: window.innerWidth < 800 ? "auto" : window.innerWidth,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: window.innerWidth < 800 ? "50px" : "200px",
          }}
        >
          {/* Item-1 */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: window.innerWidth < 800 ? "column" : "row",
              justifyContent: "center",
            }}
          >
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                }}
              >
                Interfacing Technology
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingRight: "60px",
                }}
              >
                <p>
                  Our goal is to provide social situational wisdom to every user
                  and help them drive towards personal, professional and social
                  well being. Our intial goal is to scale the product to ensure
                  our service lets every user to share and integrate their
                  situations, actions seamlessly in all formats such as text,
                  image and video to seek multi dimensional perspectives
                </p>
              </Typography>
            </div>
            <img
              className="hover-scale"
              src="/images/usecase/Feature-1.jpg"
              style={usecaseImageStyle}
            />
          </div>
          {/* Item-2 */}
          <div style={usecaseStyle}>
            {window.innerWidth > 800 && (
              <img
                className="hover-scale"
                src="/images/usecase/Feature-2.jpg"
                style={usecaseImageStyle}
              />
            )}
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                The Future of Social and Cognitive Engineering
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                <p>
                  This app is the worlds first situational awareness
                  application. A bridging link that helps every human to consume
                  information to their benefit and helps in bridging wisdom gap
                  between individual and professionals to help them drive
                  towards better decision making. This technology has the
                  potential to address many issues such as social data
                  management, managing cognitive load , increasing awareness
                  about climate issues and helping in everyday decision making
                  to help users achieve wellbeing through knowledge management
                  and explainable AI
                </p>
              </Typography>
            </div>
            {window.innerWidth < 800 && (
              <img
                className="hover-scale"
                src="/images/usecase/Feature-2.jpg"
                style={usecaseImageStyle}
              />
            )}
          </div>
          {/* Item-3 */}
          <div style={usecaseStyle}>
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                }}
              >
                Managing Cognitive Load
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingRight: "60px",
                }}
              >
                <p>
                  Helps users in managing information from over consumption and
                  assists in enhancing the power of using working memory to take
                  better decisions
                </p>
              </Typography>
            </div>
            <img
              className="hover-scale"
              src="/images/usecase/Feature-3.jpg"
              style={usecaseImageStyle}
            />
          </div>
          {/* Item-4 */}
          <div style={usecaseStyle}>
            {window.innerWidth > 800 && (
              <img
                className="hover-scale"
                src="/images/usecase/Feature-4.jpg"
                style={usecaseImageStyle}
              />
            )}
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                Situation Awareness Engine
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                <p>
                  Helps users I sharing their situations and activities to seek
                  multidimensional social perspectives to achieve well-being.
                </p>
              </Typography>
            </div>
            {window.innerWidth < 800 && (
              <img
                className="hover-scale"
                src="/images/usecase/Feature-4.jpg"
                style={usecaseImageStyle}
              />
            )}
          </div>
          {/* Item-5 */}
          <div style={usecaseStyle}>
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                }}
              >
                Climate Challenges
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingRight: "60px",
                }}
              >
                <p>
                  Helps in increasing situational awareness about climate
                  changes through interactive and engaging real time
                  understanding.
                </p>
              </Typography>
            </div>
            <img
              className="hover-scale"
              src="/images/usecase/Feature-5.jpg"
              style={usecaseImageStyle}
            />
          </div>
          {/* Item-6 */}
          <div style={usecaseStyle}>
            {window.innerWidth > 800 && <img
              className="hover-scale"
              src="/images/usecase/Feature-6.jpg"
              style={usecaseImageStyle}
            />}
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                Building Digital Assets
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                <p>
                  Our technology can be applied in helping users acquire the
                  value of data generated by users and also assists in building
                  digital assets that can be stored, utilised and transferred to
                  other users.
                </p>
              </Typography>
            </div>
            {window.innerWidth < 800 && <img
              className="hover-scale"
              src="/images/usecase/Feature-6.jpg"
              style={usecaseImageStyle}
            />}
          </div>
          {/* Item-7 */}
          <div style={usecaseStyle}>
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                }}
              >
                Managing Misinformation
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingRight: "60px",
                }}
              >
                <p>
                  Our approach could help in managing the knowledge to ensure
                  misinformation in the network through crowd engagement.
                </p>
              </Typography>
            </div>
            <img
              className="hover-scale"
              src="/images/usecase/Feature-7.jpg"
              style={usecaseImageStyle}
            />
          </div>
          {/* Item-8 */}
          <div style={usecaseStyle}>
          {window.innerWidth > 800 && <img
              className="hover-scale"
              src="/images/usecase/Feature-8.jpg"
              style={usecaseImageStyle}
            />}
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                Individuals
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingLeft: window.innerWidth < 800 ? "10px" : "60px",
                }}
              >
                <p>
                  Our technology can help individuals and professionals to
                  increase their performance through situational awareness and
                  subsequently leading to reduction in conflicts at home and
                  workplace
                </p>
              </Typography>
            </div>
            {window.innerWidth < 800 && <img
              className="hover-scale"
              src="/images/usecase/Feature-8.jpg"
              style={usecaseImageStyle}
            />}
          </div>
          {/* Item-9 */}
          <div style={usecaseStyle}>
            <div style={usecasHeadingStyle}>
              <Typography
                fontWeight="Bold"
                variant="h1"
                style={{
                  fontSize: "28px",
                  textAlign: "left",
                  width: window.innerWidth < 800 ? "80%" : "auto",
                }}
              >
                Organizations
              </Typography>
              <hr style={{ width: "100%" }} />
              <Typography
                fontWeight="Normal"
                variant="body2"
                style={{
                  fontSize: "12px",
                  textAlign: "left",
                  paddingRight: "60px",
                }}
              >
                <p>
                  Our unique technology can help startups, SME's and large
                  organisations achieve productivity through reduced costs and
                  increased situational awareness for employees and realtime
                  insights
                </p>
              </Typography>
            </div>
            <img
              className="hover-scale"
              src="/images/usecase/Feature-9.jpg"
              style={{...usecaseImageStyle,paddingBottom:window.innerWidth<800? '70px':'auto'}}
            />
          </div>
        </div>
      </Layout>
    );
}