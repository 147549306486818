import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import ListContent from "./list_content";
import ListContentMobile from "./list_content_mobile";

export default function Features() {
    return (
        <Layout>
            <div style={{ width: window.innerWidth, height: window.innerWidth > 800 ?'100vh':'65vh', position: 'relative',paddingLeft:window.innerWidth > 800 ? '100px':'' }}>
                {/* <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                    style={{ position: 'absolute', top: '20%', left: '25%' }}
                /> */}
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: window.innerWidth > 800 ?'flex-start':'center',  padding: window.innerWidth > 800 ? '':'31px 5px 25px 5px' }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '44px', textAlign:  window.innerWidth > 800 ?'left':'center' }}>Features</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '30px', textAlign: window.innerWidth > 800 ?'left':'center',padding: window.innerWidth > 800 ? '':'32px' }}>Worlds first situational wisdom network driving world towards hapiness and social well-being.</Typography>
                </div>
            </div>
            { window.innerWidth > 800 ? <ListContent /> : <ListContentMobile/>}
            {/* Download App */}
            <div
                style={{
                    width: '100%',
                    height: '30vh',
                    display: 'flex',
                    flexDirection:   window.innerWidth > 800 ?'row':'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography fontWeight="Bold" variant="h1" style={{ paddingRight: '20px' }}>Download our App</Typography>
                <div style={{  flexDirection:   window.innerWidth < 800 ?'row':'column'}}>
                <img src={"/images/google-play.png"} width="36" height="36" alt="cheers playstore" style={{ paddingRight: '20px' }} />
                <img src={"/images/appstore.png"} width="36" height="36" alt="cheers appstore" />
                </div>
            </div>
        </Layout>
    );
}