import Typography from '@mui/material/Typography';

export default function ListContent() {
    return (
        <div style={{ padding: window.innerWidth < 800 ? '25px 35px 50px 50px':'100px 100px 100px 130px' }}>
            {/* Item-1 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth < 800 ? "column" : "row" }}>
                <div style={{ width: window.innerWidth * 0.4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Multidimensional view</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/kitty/Feature-1.png" width={window.innerWidth < 800 ?'':window.innerWidth * 0.4} height={window.innerWidth < 800 ?'':window.innerHeight * 0.62} alt="feature-1" />
            </div>
            {/* Item-2 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth < 800 ? "column" : "row" }}>
                {window.innerWidth > 800 && <img src="/images/kitty/Feature-2.png" width={window.innerWidth * 0.4} height={window.innerHeight * 0.62} alt="feature-2" />}
                <div style={{ width: window.innerWidth < 800 ?'':window.innerWidth * 0.4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: window.innerWidth < 800 ? "": '20px' }}>Opportunity to realise impacts, consequences and solutions</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                {window.innerWidth < 800 && <img src="/images/kitty/Feature-2.png" alt="feature-2" />}
            </div>
            {/* Item-3 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth < 800 ? "column" : "row" }}>
                <div style={{ width:  window.innerWidth < 800 ?'auto':window.innerWidth * 0.4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>"Personalise the engine to cater the individual well-being goals"</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/kitty/Feature-3.png" width={ window.innerWidth < 800 ?'auto': window.innerWidth * 0.4} height={ window.innerWidth < 800 ?'auto':window.innerHeight * 0.62} alt="feature-3" />
            </div>
        </div>
    );
}