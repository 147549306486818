import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import Features from './features';
import ListContent from './list_content';

export default function Clubbies() {
    const topStyle = {
        position: 'relative',
        alignItems: 'center',
        padding: '150px 30px 0px 30px'
       }

       const headingCss = {
        fontSize: window.innerWidth>800?"44px":'35px',
        textAlign: window.innerWidth>800?"left":'center',
        padding: window.innerWidth>800? "auto":'15px 0px 15px 0px',
        fontWeight: window.innerWidth>800? '700':'600'
       }
    
       const titleStyle = {
        fontSize: '28px',
        paddingLeft: window.innerWidth<800?'7%':'18%',
        paddingRight:window.innerWidth<800?'7%':'18%',
        textAlign: 'center',
        paddingTop: '15%'
       }


    return (
        <Layout>
            <Grid
                container
                width={window.innerWidth}
                height={ window.innerWidth>800?'100vh':'auto'}
                style={{ backgroundColor: '#272727' }}
            >
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    pl={'12%'}
                    // pr={'4%'}
                    width={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    style={window.innerWidth <800? topStyle:{}}
                >
                    <Typography fontWeight="Bold" variant="h1" style={{ ...headingCss}}>Clubbies</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px',  textAlign:window.innerWidth>800?"left":'center'  }}>INTELLECTUAL is the latest COOL. Worlds first intent based connection network.</Typography>
                    <a href="#clubbiesDesc" style={{ textDecoration: 'none' }}><Typography fontWeight="Normal" variant="body2" style={{ textAlign: 'left', paddingTop: '10px', color: '#91FFB9' }}>LEARN MORE</Typography></a>
                </Grid>
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    width={'100%'}
                    height={window.innerWidth>800?'100vh':'80vh'}
                    position="relative"
                    textAlign='center'
                >
                    <video src="/videos/clubbies/clubbies-1.mp4" width={220} height={420} autoPlay loop muted webkit-playsinline
                        style={{ position: 'absolute', top: window.innerWidth>800? '22%':'10%', left:  window.innerWidth>800?'25%':"21%" }}
                    />
                    <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                        style={{ position: 'absolute', top: window.innerWidth>800? '22%':'8%', left:  window.innerWidth>800?'25%':"21%" }}
                    />
                </Grid>
            </Grid>
            <div id="clubbiesDesc" style={{ backgroundColor: '#272727', width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography fontWeight="Bold" variant="body2" style={{ ...titleStyle}}>Help users in making meaningful connections based on intent on the situational views. We believe connections are to be made on intellectual interests to enable meaningful connections.</Typography>
            </div>
            {/* Features */}
            <Features />
            {/* List content */}
            <ListContent />
        </Layout>
    );
}