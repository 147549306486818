import React, { useState, useEffect } from 'react';

export const rupeeUnicode = '\u{20B9}';
// export const bulletUniCode = '\u{2022}';
// export const copyUniCode = '\u{00AE}';
// export const registeredUniCode = '\u{2022}';
export const bulletUniCode = '\u{2022}';
export const registeredUniCode = '\u{00AE}';
export const copyUniCode = '\u{00A9}';
export const placeHolderImageUrl = 'https://via.placeholder.com/200x200?text=cheers';

// An em() shortcut function referred from https://medium.com/@elieslama/responsive-design-in-react-native-876ea9cd72a8
export const em = (value, width) => {
    // Precalculate Device Dimensions for better performance
    const x = width;
    // Calculating ratio from iPhone breakpoints
    const ratioX = x < 375 ? (x <= 320 ? 0.75 : 0.875) : 1;
    // Base font size value
    const baseUnit = 16;
    // Simulating EM by changing font size according to Ratio
    const unit = baseUnit * ratioX;

    return unit * value;
};

export const useMediaQuery = (query) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = e => setMatches(e.matches);
        // mediaMatch.addEventListener(handler);
        try {
            // Chrome & Firefox
            mediaMatch.addEventListener('change', handler);
        } catch (e1) {
            try {
                // Safari
                mediaMatch.addListener(handler);
            } catch (e2) {
                console.error(e2);
            }
        }
        return () => mediaMatch.removeEventListener('change', handler);
    });
    return matches;
};

