import {
    Switch,
    Route,
} from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/about';
import SituationalWisdom from '../pages/situational';
import Clubbies from '../pages/clubbies';
import Kitty from '../pages/kitty';
import Features from '../pages/features';
// import UpcomingServices from '../pages/upcoming';
import Research from '../pages/research';
// import Approach from '../pages/approach';
// import Science from '../pages/science';
import OurValue from '../pages/values';
import InvestorRelations from '../pages/investor';
import Concept from '../pages/concept';
import Method from '../pages/method';
import UseCase from '../pages/usecase';
// import Support from '../pages/support';
import FindUs from '../pages/findus';
import Privacy from '../pages/privacy';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/situational-wisdom">
                <SituationalWisdom />
            </Route>
            <Route path="/clubbies">
                <Clubbies />
            </Route>
            <Route path="/kitty">
                <Kitty />
            </Route>
            <Route path="/features">
                <Features />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            {/* <Route path="/upcoming-services">
                <UpcomingServices />
            </Route> */}
            {/* <Route path="/approach">
                <Approach />
            </Route>
            <Route path="/science">
                <Science />
            </Route> */}
            {/* Menu */}
            <Route path="/our-values">
                <OurValue />
            </Route>
            <Route path="/investor-relations">
                <InvestorRelations />
            </Route>
            <Route path="/research">
                <Research />
            </Route>
            <Route path="/concept">
                <Concept />
            </Route>
            <Route path="/method">
                <Method />
            </Route>
            <Route path="/use-case">
                <UseCase />
            </Route>
            {/* <Route path="/support">
                <Support />
            </Route> */}
            <Route path="/find-us">
                <FindUs />
            </Route>
            <Route path="/privacy-policy">
                <Privacy />
            </Route>
        </Switch>
    );
}