import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import Features from './features';
import ListContent from './list_content';

export default function SituationalWisdom() {
    const topStyle = {
        position: 'relative',
        alignItems: 'center',
        padding: '150px 30px 0px 30px'
       }

       const headingCss = {
        fontSize: window.innerWidth>800?"44px":'35px',
        textAlign: window.innerWidth>800?"left":'center',
        padding: window.innerWidth>800? "auto":'15px 0px 15px 0px',
        fontWeight: window.innerWidth>800? '700':'600'
       }
    
       const titleStyle = {
        fontSize: '28px',
        paddingLeft: window.innerWidth<800?'7%':'18%',
        paddingRight:window.innerWidth<800?'7%':'18%',
        textAlign: 'center',
        paddingTop: '15%'
       }


    return (
        <Layout>
            <Grid
                container
                width={window.innerWidth}
                height={ window.innerWidth>800?'100vh':'auto'}
                style={{ backgroundColor: '#272727' }}
            >
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    pl={'12%'}
                    // pr={'4%'}
                    width={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    style={window.innerWidth <800? topStyle:{}}
                >
                    <Typography fontWeight="Bold" variant="h1" style={{...headingCss }}>Situational Wisdom</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', textAlign:window.innerWidth>800?"left":'center' }}>Worlds first situational wisdom network driving world towards hapiness and social well-being</Typography>
                    <a href="#situationDesc" style={{ textDecoration: 'none' }}><Typography fontWeight="Normal" variant="body2" style={{ textAlign: window.innerWidth>800?"left":'center', paddingTop: '10px', color: '#91FFB9' }}>LEARN MORE</Typography></a>
                </Grid>
                <Grid
                    item
                    xs={12} md={6} lg={6}
                    width={'100%'}
                    height={window.innerWidth>800?'100vh':'80vh'}
                    position="relative"
                    textAlign='center'
                >
                    <video src="/videos/situational/situational-2.mp4" width={220} height={420} autoPlay loop muted
                        style={{ position: 'absolute', top: window.innerWidth>800? '22%':'10%', left:  window.innerWidth>800?'25%':"21%" }}
                    />
                    <img src={"/images/green-frame.png"} width="220" height="440" alt="green frame"
                        style={{ position: 'absolute', top: window.innerWidth>800? '22%':'8%', left:  window.innerWidth>800?'25%':"21%" }}
                    />
                </Grid>
            </Grid>
            <div id="situationDesc" style={{ backgroundColor: '#272727', width: '100vw', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography fontWeight="Bold" variant="body2" style={{ ...titleStyle }}>We believe in the power of meaningful content and we strive to encourage the content creators to believe in the power of useful content to add value to users life and to become a top influencer.</Typography>
            </div>
            {/* Features */}
            <Features />
            {/* Download App */}
            <div style={{ width: '100%', position: 'relative' }}>
                <img src='/images/situational/placement-area.png' alt="placement-area" />
                <div
                    style={{
                        width: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        display: 'flex',
                        flexDirection:  window.innerWidth>800?'row':'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'translate(-50%,-50%)'
                    }}
                >
                    <Typography fontWeight="Bold" variant="h1" style={{ paddingRight: '10px' }}>Download our App</Typography>
                    <div style={{flexDirection:  window.innerWidth<800?'row':'column',}}>
                        <img src={"/images/google-play.png"} width="36" height="36" alt="cheers playstore" style={{ paddingRight: '10px' }} />
                        <img src={"/images/appstore.png"} width="36" height="36" alt="cheers appstore" />
                    </div>
                </div>
            </div>
            {/* List content */}
            <ListContent />
        </Layout>
    );
}