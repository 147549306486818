import * as React from 'react';
import Box from '@mui/material/Box';
// import { Link } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

export default function SwipeableTemporaryDrawer({ children }) {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setIsOpen(open);
    };

    const options = [
        {
            'name': 'ABOUT',
            'url': '/about'
        },
        {
            'name': 'OUR VALUE',
            'url': '/our-values'
        },
        {
            'name': 'INVESTOR RELATIONS',
            'url': '/investor-relations'
        },
        {
            'name': 'RESEARCH',
            'url': '/research'
        },
        {
            'name': 'CONCEPT',
            'url': '/concept'
        },
        {
            'name': 'METHOD',
            'url': '/method'
        },
        {
            'name': 'USECASE',
            'url': '/use-case'
        },
        {
            'name': 'FIND US',
            'url': '/find-us'
        }
    ]
    // {
    //     'name': 'SUPPORT',
    //     'url': '/support'
    // },

    const mobileOptions = [
        {
            name: "Situational Wisdom",
            url: "/situational-wisdom",
            color:'#4da74d'
          },
          {
            name: "CLUBBIES",
            url: "/clubbies",
            color:'#4da74d'
          },
          {
            name: "KITTY",
            url: "/kitty",
            color:'#4da74d'
          },
          {
            name: "FEATURES",
            url: "/features",
            color:'#4da74d'
          }
    ]

    const menuOptions =
      window.innerWidth > 800
        ? options
        : [
            ...mobileOptions
            ,
            ...options,
          ];



    const list = () => (
        <Box
            sx={{ width:window.innerWidth>800? '250px':window.innerWidth }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Button onClick={toggleDrawer(false)} style={{ width: '100%', marginTop: '30px', marginBottom: '10px', textAlign: 'center' }}>
                <CloseIcon style={{ color: 'black' }} />
            </Button>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    menuOptions.map((item, index) => (
                        <Button key={item.name} style={{ margin: '10px', padding: 0, textAlign: 'center', display: 'inline-block', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <p style={{ color: 'gray', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Montserrat' }}>{item.name}</p> */}
                            {/* <Typography fontWeight="Bold" variant="body2" style={{ color: 'gray', fontSize: '12px' }}>{item.name}</Typography> */}
                            <Link component={RouterLink} to={item.url} underline="none" fontWeight="Bold" variant="body2" style={{ color: item.color? item.color:'gray', fontSize: '12px' }}>
                                {item.name}
                            </Link>
                            <Divider />
                        </Button>
                    ))
                }
            </div>
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'right'}>
                <Button onClick={toggleDrawer(true)}>{children}</Button>
                <SwipeableDrawer
                    anchor={'right'}
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
