import Typography from '@mui/material/Typography';

export default function ListContent() {
    const listTopDivStyle = {
    paddingBottom: "130px",
      paddingLeft: window.innerWidth>800?"130px":"25px",
      paddingTop: "100px",
      paddingRight:  window.innerWidth>800?"100px":"25px",
    };

    const headingStyle = {
      width: window.innerWidth>800?"100%":"auto",
      height: window.innerWidth>800?window.innerHeight * 0.62:"auto",
      display: "flex",
      flexDirection: window.innerWidth>800?"row":"column",
      padding: window.innerWidth>800?"auto":"30px 0px 30px 0px"
    };

    const subHeadingCss = {
      width : window.innerWidth>800?window.innerWidth * 0.4:"auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    };

    const imageWidth =window.innerWidth>800?window.innerWidth * 0.4:"auto"
    const imageHeight = window.innerWidth>800?window.innerHeight * 0.62:"auto"

    const alternateHeading = {
      width: window.innerWidth>800?window.innerWidth * 0.4:"auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:  window.innerWidth>800?"center":"",
    };


    return (
        <div style={{ ...listTopDivStyle }}>
            {/* Item-1 */}
            <div style={{ ...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Free for users</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/situational/Feature-1.png" width={imageWidth} height={imageHeight} alt="feature-1" />
            </div>
            {/* Item-2 */}
            <div style={{ ...headingStyle }}>
            {window.innerWidth > 800 &&  <img src="/images/situational/Feature-2.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: window.innerWidth>800? '20px':"0px" }}>Brands get reach beyond</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                {window.innerWidth < 800 &&  <img src="/images/situational/Feature-2.png" width={imageWidth} height={imageHeight} alt="feature-1" />}

            </div>
            {/* Item-3 */}
            <div style={{ ...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Valuable content</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/situational/Feature-3.png" width={imageWidth} height={imageHeight} alt="feature-1" />
            </div>
            {/* Item-4 */}
            <div style={{ ...headingStyle }}>
            {window.innerWidth > 800 && <img src="/images/situational/Feature-4.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: window.innerWidth>800? '20px':"0px" }}>Gain more followers</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
            {window.innerWidth < 800 && <img src="/images/situational/Feature-4.png" width={imageWidth} height={imageHeight} alt="feature-1" />}

            </div>
            {/* Item-5 */}
            <div style={{ ...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Cost effective brand awareness</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/situational/Feature-5.png" width={imageWidth} height={imageHeight} alt="feature-1" />
            </div>
            {/* Item-6 */}
            <div style={{...headingStyle }}>
            {window.innerWidth > 800 &&<img src="/images/situational/Feature-6.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: window.innerWidth>800? '20px':"0px" }}>Helping the followers</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
            {window.innerWidth < 800 &&<img src="/images/situational/Feature-6.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
            </div>
            {/* Item-7 */}
            <div style={{...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Contribute towards building society for free</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/situational/Feature-7.png" width={imageWidth} height={imageHeight} alt="feature-1" />
            </div>
        </div>
    );
}