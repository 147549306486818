import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import './concept.css';
import {

    usecasHeadingStyle,
    usecaseImageStyle
  } from "../../common/ComponentStyle";

export default function Concept() {
    return (
        <Layout>
            <video
                id={'concept'}
                loop
                autoPlay
                muted
                src="/videos/concept/placement-area.mp4"
                width={window.innerWidth}
                height={window.innerHeight}
                style={{ position: 'relative', overflow: 'hidden', objectFit: 'cover' }}
            />
            <div style={{ paddingRight: '0%', position: 'absolute', top: '35%', left: '10%', zIndex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '44px', textAlign: 'left', lineHeight: '64px' }}>Understanding <br />information and <br />cognitive processing</Typography>
                <hr style={{ width: '100%', textAlign: 'left', marginTop: '20px' }} />
                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingTop: '20px' }}>Growing amount of data & information, complexity in social <br />situations and limitations of working memory</Typography>
            </div>
            <div style={{
                width: window.innerWidth,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: window.innerWidth < 800 ? "50px" : "200px"
            }}>
                {/* Item-1 */}
                <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth < 800 ? "column" : "row", justifyContent: 'center' }}>
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left' }}>Data about everyday information generation</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingRight: window.innerWidth<800? 'auto':'60px' }}>
                            <p>
                                In 2020, people created 1.7 MB of data every second and by 2022, 70% of the globe’s GDP will have undergone digitization. By 2025, 200+ zettabytes of data will be in cloud storage around the globe. Every day, 306.4 billion emails are sent, and 500 million Tweets are made.
                            </p>
                        </Typography>
                    </div>
                    <img className="hover-scale" src="/images/concept/Feature-1.png" style={{ ...usecaseImageStyle }} />
                </div>
                {/* Item-2 */}
                <div style={{ width: '100%', marginTop: window.innerWidth < 800 ? "50px" : "200px", display: 'flex', flexDirection:  window.innerWidth < 800 ? "column" : "row", justifyContent: 'center' }}>
                    {window.innerWidth>800 && <img className="hover-scale" src="/images/concept/Feature-2.png" style={{ ...usecaseImageStyle }} />}
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left', paddingLeft: window.innerWidth<800? 'auto':'60px' }}>Data about everyday data consumption</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft: window.innerWidth<800? 'auto':'60px' }}>
                            <p>
                                Millinneals Daily time spent using a smartphone is 253minutes in US and Get Z spends more than 8hrs a data on internet for texting and chatting, doing work, learn new things, accessing entertainment and many more.
                            </p>
                        </Typography>
                    </div>
                    {window.innerWidth<800 && <img className="hover-scale" src="/images/concept/Feature-2.png" style={{ width: '500px', height: '300px',position:"relative",right:"50px" }} />}
                </div>
                {/* Item-3 */}
                <div style={{ width: '100%', marginTop: window.innerWidth < 800 ? "50px" : "200px", display: 'flex', flexDirection: window.innerWidth < 800 ? "column" : "row", justifyContent: 'center' }}>
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left' }}>Cognitive load & Limitations of working memory</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingRight: window.innerWidth<800? 'auto':'60px' }}>
                            <p>
                                Growing complexity of social situations and limitation of working human memory to hold unto only seven chunks of information as proposed by George Miller could lead to collision of two complex data worlds of humans leading to poor decision making and conflicts among humans, individuals and professionals
                            </p>
                        </Typography>
                    </div>
                    <img className="hover-scale" src="/images/concept/Feature-3.png" style={{ ...usecaseImageStyle }} />
                </div>
            </div>
        </Layout>
    );
}