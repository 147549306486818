import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { registeredUniCode, bulletUniCode, copyUniCode } from "../../common/Common";
import { productTitleCss,productSubTitleCss,footerTitle } from "../../common/ComponentStyle";

export default function Footer() {
    return (
        <div style={{
            width: '100%',
            overflow: 'hidden',
            backgroundColor: '#272727'
        }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    width={window.innerWidth}
                    py={window.innerWidth <800 ? '30px': '100px'}
                    px={window.innerWidth <800 ? '50px': '100px'}
                >
                    <Grid
                        item
                        xs={12} md={6} lg={6}
                        width={'100%'}
                        height="60%"
                        display="flex"
                        flexDirection="column"
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <div style={footerTitle}>
                            <Link component={RouterLink} to="/about" underline="none" fontWeight="ExtraBold" variant="body2" style={{ color: 'white', fontSize: '24px', textAlign: 'left' }}>
                                CHEERS WISDOM
                            </Link>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign: 'left' }}>Advancing Human Consciousness</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign:  window.innerWidth> 800 ?"left":"center",width:"100%" }}>The WISDOM NETWORK</Typography>
                        </div>
                        <div style={{ width: window.innerWidth> 800 ?"100%":"auto", marginTop: '50px', display: 'flex', flexDirection: 'row', alignContent: 'flex-start', justifyItems: 'flex-start' }}>
                            <a href="#homeLogo" style={{ textDecoration: 'none' }}>
                                <img src={"/images/cheers_f.png"} width="40" height="40" alt="twitter" style={{ textAlign: 'left', marginRight: '10px' }} />
                            </a>
                            <a href="https://twitter.com/CheersWisdom" style={{ textDecoration: 'none' }}>
                                <img src={"/images/twitter_f.png"} width="40" height="40" alt="twitter" style={{ textAlign: 'left', marginRight: '10px' }} />
                            </a>
                            <a href="https://www.linkedin.com/company/cheers-wisdom/" style={{ textDecoration: 'none' }}>
                                <img src={"/images/linkedin_f.png"} width="40" height="40" alt="linkedin" style={{ textAlign: 'left', marginRight: '10px' }} />
                            </a>
                            <a href="#" style={{ textDecoration: 'none' }}>
                                <img src={"/images/youtube_f.png"} width="40" height="40" alt="youtube" style={{ textAlign: 'left', marginRight: '10px' }} />
                            </a>
                            <a href="#" style={{ textDecoration: 'none' }}>
                                <img src={"/images/phone_f.png"} width="40" height="40" alt="phone footer" style={{ textAlign: 'left' }} />
                            </a>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12} md={3} lg={3}
                        // pl={'14%'}
                        width={'100%'}
                        height="60%"
                        display="flex"
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography fontWeight="Bold" variant="body2" style={productTitleCss}>Products</Typography>
                        <Link component={RouterLink} to="/situational-wisdom" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Situational Wisdom
                        </Link>
                        <Link component={RouterLink} to="/clubbies" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Clubbies
                        </Link>
                        <Link component={RouterLink} to="/kitty" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Kitty
                        </Link>
                        <Link component={RouterLink} to="/upcoming-services" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Upcoming Services
                        </Link>
                    </Grid>
                    <Grid
                        item
                        xs={12} md={3} lg={3}
                        // pl={'14%'}
                        width={'100%'}
                        height="60%"
                        display="flex"
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                    >
                        <Typography fontWeight="Bold" variant="body2" style={productTitleCss}>Company</Typography>
                        <Link component={RouterLink} to="/about" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            About Us
                        </Link>
                        <Link component={RouterLink} to="/research" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Research
                        </Link>
                        <Link component={RouterLink} to="/our-values" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Our Values
                        </Link>
                        <Link component={RouterLink} to="/find-us" underline="hover" fontWeight="Normal" variant="body2" style={productSubTitleCss}>
                            Find Us
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <div style={{ width: '100%', height:window.innerWidth>800 ?  '44px':'75px', backgroundColor: 'white', display: 'flex', flexDirection: window.innerWidth< 800 ?"column":"row", alignContent: 'space-between', alignItems: 'center' }}>
                <Typography fontWeight="Normal" variant="body2" style={{ width: '100%', color: 'black', fontSize: '12px', textAlign: 'center',padding:window.innerWidth>800?'':'10px'  }}>{copyUniCode}2022 CHEERS WISDOM. All Rights Reserved.</Typography>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <Link component={RouterLink} to="/privacy-policy" underline="hover" fontWeight="Normal" variant="body2" style={{ width: '100%', color: 'black', fontSize: '12px', textAlign: 'center' }}>
                        Privacy Policy
                    </Link>
                    <Link component={RouterLink} to="/terms-of-service" underline="hover" fontWeight="Normal" variant="body2" style={{ width: '100%', color: 'black', fontSize: '12px', textAlign: 'center' }}>
                        Terms of Service
                    </Link>
                </div>
            </div>
        </div>
    );
}