import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SwipeableTemporaryDrawer from "./menu";

export default function NavBar() {
    return (
        <Box sx={{ flexGrow: 1 }}>
        <Grid
            container
            position={'absolute'}
            px={'100px'}
            top={'20px'}
            left={0}
            width={window.innerWidth}
            minHeight={'40px'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex='99'
            paddingLeft={window.innerWidth <800 ? '0px': '100px'}
            paddingRight={window.innerWidth <800 ? '0px': '100px'}
        >
            <Grid item xs={8} md={2} lg={2} textAlign={'center'} >
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <img id="homeLogo" src={"/images/logo.png"} width="152" height="32" alt="cheers logo" />
                </Link>
            </Grid>
            {window.innerWidth> 800 && <Grid item xs={0} md={2} lg={2} textAlign={'right'}>
                <Link to="/situational-wisdom" style={{ textDecoration: 'none' }}>
                    {/* <p style={{ color: 'white', fontSize: '18px', fontFamily: 'Montserrat' }}>Situational Wisdom</p> */}
                    <Typography fontWeight="Normal" variant="body2">Situational Wisdom</Typography>
                </Link>
            </Grid>}
            {window.innerWidth> 800 &&  <Grid item xs={0} md={2} lg={2} textAlign={'right'}>
                <Link to="/clubbies" style={{ textDecoration: 'none' }}>
                    {/* <p style={{ color: 'white', fontSize: '18px', fontFamily: 'Montserrat' }}>Clubbies</p> */}
                    <Typography fontWeight="Normal" variant="body2">Clubbies</Typography>
                </Link>
            </Grid>}
            {window.innerWidth> 800 && <Grid item xs={0} md={2} lg={2} textAlign={'right'}>
                <Link to="/kitty" style={{ textDecoration: 'none' }}>
                    {/* <p style={{ color: 'white', fontSize: '18px', fontFamily: 'Montserrat' }}>Kitty</p> */}
                    <Typography fontWeight="Normal" variant="body2">Kitty</Typography>
                </Link>
            </Grid>}
            {window.innerWidth> 800 &&  <Grid item xs={0} md={2} lg={2} textAlign={'right'}>
                <Link to="/features" style={{ textDecoration: 'none' }}>
                    {/* <p style={{ color: 'white', fontSize: '18px', fontFamily: 'Montserrat' }}>Features</p> */}
                    <Typography fontWeight="Normal" variant="body2">Features</Typography>
                </Link>
            </Grid>}
            <Grid item xs={4} md={2} lg={2} textAlign={'right'}>
                <SwipeableTemporaryDrawer>
                    <img src={"/images/menu.png"} width="22" height="14" alt="menu" />
                </SwipeableTemporaryDrawer>
            </Grid>
        </Grid>
    </Box>
    );
}