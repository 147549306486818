import Typography from '@mui/material/Typography';

export default function ListContent() {

    const imageWidth =window.innerWidth>800?window.innerWidth * 0.4:"auto"
    const imageHeight = window.innerWidth>800?window.innerHeight * 0.62:"auto"

    const listTopDivStyle = {
        paddingBottom: "130px",
          paddingLeft: window.innerWidth>800?"130px":"25px",
          paddingTop: "100px",
          paddingRight:  window.innerWidth>800?"100px":"25px",
        };
    
        const headingStyle = {
          width: window.innerWidth>800?"100%":"auto",
          height: window.innerWidth>800?imageHeight:"auto",
          display: "flex",
          flexDirection: window.innerWidth>800?"row":"column",
          padding: window.innerWidth>800?"auto":"30px 0px 30px 0px"
        };
    
        const subHeadingCss = {
          width : window.innerWidth>800?window.innerWidth * 0.42:"auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        };
    

    
        const alternateHeading = {
          width: window.innerWidth>800?imageWidth:"auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems:  window.innerWidth>800?"center":"",
        };
    


    return (
        <div style={{ ...listTopDivStyle }}>
            {/* Item-1 */}
            <div style={{...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Get FREE matches for every posts</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/clubbies/Feature-1.png" width={imageWidth} height={imageHeight} alt="feature-1" />
            </div>
            {/* Item-2 */}
            <div style={{...headingStyle }}>
            {window.innerWidth > 800 &&<img src="/images/clubbies/Feature-2.png" width={imageWidth} height={imageHeight} alt="feature-2" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '20px' }}>Filter to personalise your match</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                {window.innerWidth < 800 &&<img src="/images/clubbies/Feature-2.png" width={imageWidth} height={imageHeight} alt="feature-2" />}
            </div>
            {/* Item-3 */}
            <div style={{...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Find meaningfull connections based on Intellectual interest</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/clubbies/Feature-3.png" width={imageWidth} height={imageHeight} alt="feature-3" />
            </div>
            {/* Item-4 */}
            <div style={{...headingStyle }}>
                {window.innerWidth > 800 && <img src="/images/clubbies/Feature-4.png" width={imageWidth} height={imageHeight} alt="feature-4" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '20px' }}>Be the first to send Hi-Fi</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                {window.innerWidth < 800 && <img src="/images/clubbies/Feature-4.png" width={imageWidth} height={imageHeight} alt="feature-4" />}
            </div>
            {/* Item-5 */}
            <div style={{...headingStyle }}>
                <div style={{ ...subHeadingCss }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingRight: '20px' }}>Mutual Hi-Fi makes it clubby</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                <img src="/images/clubbies/Feature-5.png" width={imageWidth} height={imageHeight} alt="feature-5" />
            </div>
            {/* Item-6 */}
            <div style={{...headingStyle }}>
            {window.innerWidth> 800 &&<img src="/images/clubbies/Feature-6.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
                <div style={{ ...alternateHeading }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '20px' }}>Connections are based out of AI</Typography>
                    <hr style={{ width: '100%' }} />
                </div>
                {window.innerWidth< 800 &&<img src="/images/clubbies/Feature-6.png" width={imageWidth} height={imageHeight} alt="feature-1" />}
            </div>
        </div>
    );
}