import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import { usecasHeadingStyle } from "../../common/ComponentStyle";

export default function Research() {
    const usecaseImageStyle= {
        width: window.innerWidth < 800 ? "auto" :'480px',
        height: window.innerWidth < 800 ? "auto" :'340px',
        padding: window.innerWidth < 800 ? "25px" :"auto"
      }

    return (
        <Layout>
            <div style={{
                position: 'relative',
                width: window.innerWidth,
                height: '80vh'
            }}>
                <img src="/images/research/placement-area.png" width={window.innerWidth} height={window.innerHeight * 0.8} style={{ position: 'absolute', top: 0, left: 0 }} />
                <Typography fontWeight="Bold" variant="h1" style={{ position: 'absolute', top: '50%', left: window.innerWidth< 800 ?'0':'10%', fontSize: '44px', textAlign: window.innerWidth< 800 ?'center':'left',width:window.innerWidth< 800 ?'100%':'0' }}>Research</Typography>
                <Typography fontWeight="Normal" variant="body2" style={{ position: 'absolute', top: '65%', left: window.innerWidth< 800 ?'0':'10%', fontSize: '14px', textAlign: window.innerWidth< 800 ?'center':'left',width:window.innerWidth< 800 ?'100%':'0' }}>Bringing the world closer by advancing in Social Situational Awareness</Typography>
            </div>
            <div style={{
                width: window.innerWidth,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '100px'
            }}>
                {/* Item-1 */}
                <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'center' }}>
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left' }}>Mini Bunker/Kitty</Typography>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left' }}>Scaling Situational <br />Black Box</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingRight: '60px' }}>
                            <p>
                                Expanding our social situational awareness technology to individuals, professionals, SMEs, Startups and Organisations.
                            </p>
                            <p>
                                Our focus will be to partner with collaborators to build situational wisdom tools to build situational awareness about climate change and to support organisations supporting the cause.
                            </p>
                        </Typography>
                    </div>
                    <img src="/images/research/Feature-1.jpg" style={{...usecaseImageStyle }} />
                </div>
                {/* Item-2 */}
                <div style={{ width: '100%',  marginTop: window.innerWidth< 800 ?'50px' : '250px', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'center' }}>
                    {window.innerWidth> 800  && <img src="/images/research/Feature-2.jpg" style={{...usecaseImageStyle }} />}
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>Sequential Intelligent Systems</Typography>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>Research focused to <br />reduce false</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>
                            <p>
                                Our aim will be to address knowledge sequence issues and fake information issues through a design thinking approach. Our simulation of initial model present us with satisfying preliminary result that enabled us to continue our fight upon becoming a network where fake is eliminated by people. At this stage we would take on blockchain and Cheers token will be related.
                            </p>
                        </Typography>
                    </div>
                    {window.innerWidth< 800  && <img src="/images/research/Feature-2.jpg" style={{...usecaseImageStyle }} />}
                </div>
                {/* Item-3 */}
                <div style={{ width: '100%',  marginTop: window.innerWidth< 800 ?'50px' : '250px', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'center' }}>
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left' }}>Well Being Meters</Typography>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left' }}>User Centric Wellness <br />Design</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingRight: '60px' }}>
                            <p>
                                Our Research will focus on building easy well-being meters that can personalise to each person needs dynamically will help manage cognitive load and take valueable information to longterm memory.
                            </p>
                        </Typography>
                    </div>
                    <img src="/images/research/Feature-3.jpg" style={{...usecaseImageStyle }} />
                </div>
                {/* Item-4 */}
                <div style={{ width: '100%', marginTop: window.innerWidth< 800 ?'50px' : '250px', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'center' }}>
                {window.innerWidth> 800  && <img src="/images/research/Feature-4.jpg" style={{...usecaseImageStyle }} />}
                    <div style={usecasHeadingStyle}>
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>Digital Assets & Data Lakes</Typography>
                        <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '28px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>Value for Users</Typography>
                        <hr style={{ width: '100%' }} />
                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingLeft:  window.innerWidth< 800 ?'0px' :'60px' }}>
                            <p>
                                Enabling users to use of crypto tokens to create digital assets by creating, investing and trading in content that is aimed at improving wellness of society.
                            </p>
                        </Typography>
                    </div>
                {window.innerWidth< 800  && <img src="/images/research/Feature-4.jpg" style={{...usecaseImageStyle }} />}
                </div>
                <div style={{ width: '100%',  marginTop: window.innerWidth< 800 ?'50px' : '250px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ fontSize:  window.innerWidth< 800 ?'30px':'44px',textAlign: window.innerWidth<800 ? 'center':'', padding:window.innerWidth>800?'0':'25px 25px 5px 25px' }}>
                        Our work based on below research articles:
                    </Typography>
                    <div style={{ width: '100%', marginTop:  window.innerWidth> 800 ?'100px':'30px',  marginBottom:  window.innerWidth> 800 ?'auto':'100px',height:  window.innerWidth< 800 ?"auto":"330", display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", justifyContent: 'space-evenly', paddingLeft: window.innerWidth< 800 ?'25%':'0',gap: window.innerWidth< 800 ?'30px':'0' }}>
                        <a href="https://www.researchgate.net/publication/342838474_Who%27s_That_-_Social_Situation_Awareness_for_Behaviour_Support_Agents_A_Feasibility_Study" style={{ textDecoration: 'none' }}>
                            <div style={{
                                width: '240px', height: '200px', padding: '30px',
                                backgroundImage: "url(/images/research/card.png)",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '18px', textAlign: 'center', color: '#515151' }}>
                                    Situational Awareness
                                </Typography>
                                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', color: 'black' }}>
                                    Behaviour support agents need to be aware of the social environment of the user in order to be able to provide comprehensive support. However, this is a feature that is currently lacking in existing systems.
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <hr style={{ width: '100%', color: 'black', backgroundColor: 'black' }} />
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', color: 'black' }}>
                                            To Learn more
                                        </Typography>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', backgroundColor: '#515151', borderColor: 'white', borderStyle: 'solid', padding: '4px', color: 'white', borderRadius: '2px' }}>
                                            Click Here
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4207727/" style={{ textDecoration: 'none' }}>
                            <div style={{
                                width: '240px',
                                height: '200px',
                                padding: '30px',
                                backgroundImage: "url(/images/research/card.png)",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '18px', textAlign: 'center', color: '#515151' }}>
                                    Memory & Wellness
                                </Typography>
                                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', color: 'black' }}>
                                    Working memory is the retention of a small amount of information in a readily accessible form. It facilitates planning, comprehension, reasoning, and problem-solving.
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <hr style={{ width: '100%', color: 'black', backgroundColor: 'black' }} />
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', color: 'black' }}>
                                            To Learn more
                                        </Typography>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', backgroundColor: '#515151', borderColor: 'white', borderStyle: 'solid', padding: '4px', color: 'white', borderRadius: '2px' }}>
                                            Click Here
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="https://www.medicalnewstoday.com/articles/social-media-and-mental-health" style={{ textDecoration: 'none' }}>
                            <div style={{
                                width: '240px',
                                height: '200px',
                                padding: '30px',
                                backgroundImage: "url(/images/research/card.png)",
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '18px', textAlign: 'center', color: '#515151' }}>
                                    Misinformation
                                </Typography>
                                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', color: 'black' }}>
                                    Social media use can lead to low quality sleep and harm mental health. It has associations with depression, anxiety, and low self-esteem.
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <hr style={{ width: '100%', color: 'black', backgroundColor: 'black' }} />
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', color: 'black' }}>
                                            To Learn more
                                        </Typography>
                                        <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', backgroundColor: '#515151', borderColor: 'white', borderStyle: 'solid', padding: '4px', color: 'white', borderRadius: '2px' }}>
                                            Click Here
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    );
}