import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Layout from "../layout";

export default function InvestorRelations() {
    return (
        <Layout>
            <div className="styleDiv">
                <img src="/images/investor/placement-area.jpg" width={ window.innerWidth >600?440:window.innerWidth-70} height={ window.innerWidth >800?320:"auto"} className="inverstorImgStyle" />
                <div style={{ height: '320px', display: 'flex', flexDirection: 'column', paddingLeft: window.innerWidth >800?'60px':"10px" }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ fontSize: window.innerWidth >800?'44px':"30px", textAlign: 'left' }}>Invest in <br />Cheers Wisdom</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign: 'left', paddingTop: '40px' }}>Join the wisdom revolution to make the world a better place.</Typography>
                    <Link component={RouterLink} to="/find-us" underline="none" fontWeight="Normal" variant="body2" style={{ fontSize: '22px', color: '#91FFB9', textAlign: 'left', paddingTop: '40px' }}>
                        GET IN TOUCH
                    </Link>
                </div>
            </div>
        </Layout>
    );
}