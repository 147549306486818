import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';


const data = [
    {
        'id': 1,
        'title': 'Kitty',
        'titleRightIcon': '/images/kitty/kitty-icon.png',
        'subtitle': 'Kitty helps in managing growing information and data towards users advantage',
        'image': null,
        'video': '/videos/kitty/kitty.mp4'
    },
    {
        'id': 2,
        'title': 'Memories',
        'titleRightIcon': null,
        'subtitle': 'Save your memories anonymously',
        'image': null,
        'video': '/videos/kitty/memories.mp4'
    },
    {
        'id': 3,
        'title': 'Cheers Room',
        'titleRightIcon': null,
        'subtitle': 'Multidimensional impacts. Room to understand the impacts and opportunities. Users can personalise situation blackbox to cater needs on individual well-being goals. Cheer room is to understand impact, not to get influenced.We always strive to make it user centric wellness engine rather than focusing on making users addictive.',
        'image': null,
        'video': '/videos/kitty/cheers-room.mp4'
    },
    {
        'id': 4,
        'title': 'Kitty Reach',
        'titleRightIcon': '/images/kitty/kitty-reach-icon.png',
        'subtitle': 'Helps brands and content creators reach to targeted consumers. Helpful content and limitless growth.',
        'image': null,
        'video': '/videos/kitty/kitty-reach.mp4'
    }
]

export default function Features() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const autoPlayRef = useRef();

    const nextSlide = () => {
        if (currentSlide === data.length - 1) {
            return;
        }

        setCurrentSlide(currentSlide + 1)
    }

    const previousSlide = () => {
        if (currentSlide === 0) {
            return;
        }
        else if (currentSlide > 0 && currentSlide <= data.length - 1) {
            return setCurrentSlide(currentSlide - 1)
        }
    }

    // Use for autoplay
    // useEffect(() => {
    //     autoPlayRef.current = nextSlide;
    // })

    // useEffect(() => {
    //     const play = () => {
    //         autoPlayRef.current()
    //     }

    //     const interval = setInterval(play, 3000)
    //     return () => clearInterval(interval)
    // }, [])

    return (
        <div style={{
            backgroundColor: '#272727',
            width: '100vw',
            height: '100vh'
        }}>
            <div style={{ width: '100%', height: '440px', display: 'flex', flexDirection: window.innerWidth < 800 ? "column" : "row" }}>
                <div style={{ width: window.innerWidth < 800 ? '80%':'100%', paddingLeft:window.innerWidth < 800 ? '':'60px', display: 'flex', padding:window.innerWidth < 800 ? "25px 25px 55px 50px":'0', height: '440px', flexDirection: 'column', justifyContent: 'center', alignItems:window.innerWidth < 800 ? 'center' : 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'start', alignItems: 'center' }}>
                        <Typography fontWeight="Bold" variant="h1" style={{ textAlign: 'left' }}>{data[currentSlide].title}</Typography>
                        {
                            (data[currentSlide].titleRightIcon !== null)
                                ?
                                <img src={data[currentSlide].titleRightIcon} width="40" height="26" style={{ paddingLeft: '5px' }} alt="hihi icon" />
                                :
                                <div />
                        }
                    </div>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', textAlign: window.innerWidth < 800 ?'center' :'left' }}>{data[currentSlide].subtitle}</Typography>
                </div>
                {
                    (data[currentSlide].video !== null) ?
                        <div style={{ position: 'relative', width: '100%', height: '440px', paddingRight: '80px', textAlign: 'center' }}>
                            <video src={data[currentSlide].video} width={220} height={410} autoPlay loop muted
                                style={{ position: 'absolute', top: window.innerWidth < 800 ?'16px':'10px', left:  window.innerWidth < 800 ?'':'44%' }}
                            />
                            <img src={"/images/green-frame.png"} width={window.innerWidth < 800 ?'230':"220"} height="440" alt="green frame"
                                style={{ position:  window.innerWidth < 800 ?'':'absolute', top: 0 }}
                            />
                        </div>
                        :
                        <div style={{ width: '100%', height: '440px', textAlign: 'center' }}>
                            <img src={data[currentSlide].image} width="260" height="440" alt="images" />
                        </div>
                }
            </div>
            {/* Slide Indicators */}
            <div
                style={{
                    width: window.innerWidth,
                    paddingTop:  window.innerWidth < 800 ?'60%':'60px',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div onClick={previousSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === 0) ? <div /> : <ArrowCircleLeftOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                        key={1}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 0 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(0); }}
                    />
                    <button
                        key={2}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 1 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(1); }}
                    />
                    <button
                        key={3}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 2 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', marginRight: '10px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(2); }}
                    />
                    <button
                        key={4}
                        style={{ border: '0', height: '8px', width: '8px', backgroundColor: `${currentSlide === 3 ? '#91FFB9' : 'gray'}`, borderRadius: '8px', padding: '0' }}
                        onClick={(e) => { e.preventDefault(); setCurrentSlide(3); }}
                    />
                </div>
                <div onClick={nextSlide} style={{ width: '100%', textAlign: 'center' }}>
                    {
                        (currentSlide === data.length - 1) ? <div /> : <ArrowCircleRightOutlinedIcon htmlColor='#91FFB9' />
                    }
                </div>
            </div>
        </div>
    );
}