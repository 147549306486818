import Typography from "@mui/material/Typography";

export default function ListContentMobile() {
  const imageWidth = window.innerWidth > 800 ? window.innerWidth * 0.4 : "auto";
  const imageHeight =
    window.innerWidth > 800 ? window.innerHeight * 0.62 : "auto";

  const listTopDivStyle = {
    paddingBottom: "130px",
    paddingLeft: window.innerWidth > 800 ? "130px" : "25px",
    paddingTop: "100px",
    paddingRight: window.innerWidth > 800 ? "100px" : "25px",
  };

  const headingStyle = {
    width: window.innerWidth > 800 ? "100%" : "auto",
    height: window.innerWidth > 800 ? imageHeight : "auto",
    display: "flex",
    flexDirection: window.innerWidth > 800 ? "row" : "column",
  };

  const subHeadingCss = {
    width: window.innerWidth > 800 ? window.innerWidth * 0.42 : "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  };

  const alternateHeading = {
    width: window.innerWidth > 800 ? imageWidth : "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: window.innerWidth > 800 ? "center" : "",
  };

  return (
    <div style={{ ...listTopDivStyle }}>
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            Express to create a better world
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px" }}
          >
            Contribute in building a better community
          </Typography>
        </div>
        <img
          src="/images/features/Feature-1.png"
          width={220}
          height={440}
          alt="feature-1"
          style={{ padding: "10px 50px 78px 50px" }}
        />
      </div>
      {/* Item-2 */}
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            Kitty Reach
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px", alignItems: "left" }}
          >
            It is the count of number of kitty's reached. Measure of life's
            enabled
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "440px",
            padding: "50px 50px 78px 50px",
          }}
        >
          <video
            src="/videos/features/k_reach.mp4"
            width="219"
            height="410"
            autoPlay
            loop
            muted
            style={{ position: "absolute", top: "10px" }}
          />
          <img
            src={"/images/green-frame.png"}
            width="220"
            height="440"
            alt="green frame"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      </div>

      {/* Item-3 */}
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            High Talks
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px", alignItems: "left" }}
          >
            Appreciate someone for their talent
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "440px",
            padding: "50px 50px 78px 50px",
          }}
        >
          <video
            src="/videos/features/high_talk.mp4"
            width="219"
            height="410"
            autoPlay
            loop
            muted
            style={{ position: "absolute", top: "10px" }}
          />
          <img
            src={"/images/green-frame.png"}
            width="220"
            height="440"
            alt="green frame"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      </div>

      {/* Item-4 */}
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            Thunder
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px", alignItems: "left" }}
          >
            Show people what you got's reached. Measure of life's enabled
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "440px",
            padding: "50px 50px 78px 50px",
          }}
        >
          <video
            src="/videos/features/thunder_1.mp4"
            width="219"
            height="410"
            autoPlay
            loop
            muted
            style={{ position: "absolute", top: "10px" }}
          />
          <img
            src={"/images/green-frame.png"}
            width="220"
            height="440"
            alt="green frame"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      </div>

      {/* Item-5 */}
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            Clubbies
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px", alignItems: "left" }}
          >
            Meet the like minded people around the world
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "440px",
            padding: "50px 50px 78px 50px",
          }}
        >
          <video
            src="/videos/features/clubbies.mp4"
            width="219"
            height="410"
            autoPlay
            loop
            muted
            style={{ position: "absolute", top: "10px" }}
          />
          <img
            src={"/images/green-frame.png"}
            width="220"
            height="440"
            alt="green frame"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      </div>

      {/* Item-6 */}
      <div style={{ ...headingStyle }}>
        <div style={{ ...subHeadingCss, padding: "0px 25px 25px 25px" }}>
          <Typography
            fontWeight="SemiBold"
            variant="h1"
            style={{ fontSize: "28px", alignItems: "left" }}
          >
            Hifi
          </Typography>
          <Typography
            fontWeight="Normal"
            variant="body2"
            style={{ fontSize: "14px", paddingTop: "10px", alignItems: "left" }}
          >
            Hi-fi to start a conversation
          </Typography>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "440px",
            padding: "50px 50px 78px 50px",
          }}
        >
          <video
            src="/videos/features/hifi.mp4"
            width="219"
            height="410"
            autoPlay
            loop
            muted
            style={{ position: "absolute", top: "10px" }}
          />
          <img
            src={"/images/green-frame.png"}
            width="220"
            height="440"
            alt="green frame"
            style={{ position: "absolute", top: 0 }}
          />
        </div>
      </div>
    </div>
  );
}
