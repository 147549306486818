import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Layout from "../layout";
import { aboutTtitleStyle } from "../../common/ComponentStyle";

const data = [
    {
        'imageUrl': '/images/about/Sohail.jpg',
        'name': 'Sohail',
        'designation': 'Chief Executive Officer (CEO)',
        'description': "A passionate thinker and innovator. He loves to apply design thinking to solve the problems of the society. Sohail is passionate about technology, history, philosophy and design. He is a self-taught Artificial intelligence and blockchain product designer who aims to innovate cross multiple industries to apply design thinking and technology to enable consumers. He started as product designer and artificial intelligence researcher in 2017 to apply data sciences to filmmaking. His passion about understanding data to create value for organizations and consumers has driven him to start his entrepreneurial journey. Sohail is currently leading CHEERS WISDOM Pvt ltd and Scriptcraft as CEO and has founded the company. He holds patent in cognitive computing to understand emotional intelligence of user through social data. He recently applied for another patent in the field of AI and blockchain.",
        'linkedInUrl': 'https://www.linkedin.com/in/sohail-mahammad-6412b2171/',
        'twitterUrl': '#'
    },
    {
        'imageUrl': '/images/about/Faubert.jpeg',
        'name': 'Jocelyn Faubert',
        'designation': 'Chief Scientific Officer (CSO)',
        'description': "A full-time professor at the Université de Montréal and has held the NSERC-Essilor Industrial Research Chair on Visual Function for 18 years (2001-2018). His research interests are diverse dealing from low to high-level perceptual and cognitive processing, human performance, development, aging, optics and photonics spanning from a fundamental perspective to translational research. He has published over 200 research articles in peer-review outlets in numerous fields including neuroscience, psychology, sports science, vision science, engineering, optics-photonics, rehabilitation, development, gerontology, virtual reality, and general science journals. He has 20+ distinct patents & patents pending spanning from biophotonics, brain imaging, optical designs, and behavioural procedural methods for which the great majority have been transferred to established companies or spinoffs. He was a founder and chief science officer of several spinoff companies and is the inventor of the NeuroTracker system that is now deployed globally used in most of the major professional sports leagues and performance organizations. His work has received extensive media coverage in venues such as The New York Times, The NewYorker, Wired Magazine, The Times, The Scientist, The Week, The Sun, Nature.com, National Post, CBC, PBS, CTV News, etc. He was the recipient of the prestigious Medical Research Council of Canada (MRC) Scientist award in 1999 and prior to that also was an MRC Scholar and received salary awards from the Quebec government research councils. He was also recognised for his technology transfer from university to industry by receiving an ADRIQ-NSERC award for his involvement in the transfer of university to industry and the Genesis Award from BioQuebec for transfer-emergence.",
        'linkedInUrl': 'https://www.linkedin.com/in/jocelyn-faubert-255725a',
        'twitterUrl': '#'
    },
    {
        'imageUrl': '/images/about/Jayanth.jpg',
        'name': 'Jayanth',
        'designation': 'Chief Financial Officer (CFO)',
        'description': "Chartered Accountant, Company Secretary and currently pursuing a Ph.D. in Economics from the Indian Institute of Management Kozhikode. He is a researcher by profession and is passionate about teaching. His research areas lie in the field of disruptive technologies (such as Artificial Intelligence, Satellite Imagery, Machine learning) for development. In addition to this, he also manages the finance, tax, accounts, auditing, and management-related issues in AI Movies. He has experience working in tech-based start-ups as a co-founder. Further, his works have been published in several international journals and he contributed articles in popular media outlets.",
        'linkedInUrl': 'https://www.linkedin.com/in/reddy-sai-shiva-jayanth/',
        'twitterUrl': '#'
    },
    {
        'imageUrl': '/images/about/Javed.jpg',
        'name': 'Javed',
        'designation': 'Investor & Chief Operating Officer (COO)',
        'description': "A customer focused and performance driven operations strategist with more than 7+ experience in IT industry Worked with leading IT industry leaders in Finance, Medical & Product based FAANG company. He has graduated with a Master's degree in Computer Science and served as Editorial Board Member for various IT conferences. Demonstrated ability to adapt to new challenges and deliver meaningful and measurable goals.",
        'linkedInUrl': 'https://www.linkedin.com/in/javedvmware/',
        'twitterUrl': '#'
    },
    {
        'imageUrl': '/images/about/profesor-img.jpg',
        'name': 'Dr. Kanad Ray',
        'designation': 'Scientific Advisor',
        'description': "Dr. Kanad Ray is a Professor and Head of Physics at the Amity School of Applied Sciences Physics Amity University Rajasthan (AUR), Jaipur, India. He has obtained M.Sc & Ph.D. degrees inPhysics from Calcutta University & Jadavpur University, West Bengal, India. In an academic career spanning over 26 years, he has published and presented research papers in several national and international journals and conferences in India and abroad. He has authored a book on the Electromagnetic Field Theory. Prof. Ray’s current research areas of interest include cognition, communication,electromagnetic field theory, antenna & wave propagation, microwave, computational biology, and applied physics. He has  guided 8 Ph.D. scholars in various interdisciplinary fields. He has served as Editor of the Springer Book Series.  He was an Associated Editor of the Journal of Integrative Neuroscience published by IOS Press, Netherlands. He has traveled to more than a dozen countries on different academic missions. He has established an MOU between his University and the University of Montreal, Canada for various joint research activities. He has also established collaboration with National Institute for Materials Science(NIMS), Japan for joint research activities and visits NIMS as a Visiting Scientist. He organizes international conference series such as TCCE and TEHI as General Chair. He was an IEEE Executive Committee member of the Rajasthan",
        'linkedInUrl': 'https://www.linkedin.com/in/kanad-ray-45373b86/',
        'twitterUrl': '#'
    }

    
    
]

export default function About() {
    return (
        <Layout>
            <div style={{
                position: 'relative',
                width: window.innerWidth,
                height: '80vh'
            }}>
                <img src="/images/about/placement-area.png" width={window.innerWidth} height={window.innerHeight * 0.8} style={{ position: 'absolute', top: 0, left: 0 }} />
                <Typography fontWeight="Bold" variant="h1" style={{ position: 'absolute', top: '50%', left: '10%', fontSize: '44px', textAlign: 'left' }}>About Us</Typography>
            </div>
            <div style={{...aboutTtitleStyle}}>
                <div style={{ width: '100%', padding: window.innerWidth >800 ?'80px':'100px 20px 0px 25px', display: 'flex', flexDirection: 'column' }}>
                    <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '44px', textAlign: 'left',padding:'10px 30px 0px 30px' }}>Our Mission</Typography>
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', textAlign: 'left', paddingTop: '20px',padding:'10px 20px 0px 25px' }}>Our mission at cheers wisdom is to develop technology with design thinking at the core of our design. Our mission would focus on increasing social situational wisdom to each person on the planet that could subsequently help the individual acheive personal, professional and social well-being goals. We believe in building powerful tools that could help humanity handle cognitive load and information and knowledge mismanagement.</Typography>
                </div>
                <div style={{ width:  window.innerWidth >800 ?'100%':'80%', padding:  window.innerWidth >800 ?'80px':'35px 45px 80px 25px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <img src="/images/about/Feature-1.jpg" width={'48%'} />
                        <img src="/images/about/Feature-2.jpg" width={'48%'} />
                    </div>
                    <img src="/images/about/Feature-3.jpg" style={{ paddingTop: '4%' }} />
                </div>
            </div>
            <div style={{
                width: window.innerWidth,
                // height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: window.innerWidth >800 ?'44px':'35px', textAlign: 'left' }}>Meet Our Leaders</Typography>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', paddingTop: '100px' }}>
                    {/* Item-1 */}
                    <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row" }}>
                        <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[0].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="sohail" />
                        </div>
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems:  window.innerWidth >800 ?'flex-start':'center' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px' }}>{data[0].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', color: 'red' }}>{data[0].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', paddingRight: window.innerWidth> 800? '120px':"0px",padding: window.innerWidth> 800? "auto":"20px 35px 5px 35px" }}>{data[0].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[0].linkedInUrl} style={{ textDecoration: 'none' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                    </div>
                    {/* Item-2 */}
                    <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", marginTop: '100px' }}>
                    {window.innerWidth <800 &&<div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                           <img src={data[1].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="goutham" />
                        </div>}
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px', paddingLeft: '100px' }}>{data[1].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', paddingLeft: '100px', color: 'red' }}>{data[1].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', paddingLeft:  window.innerWidth< 800? "auto":'100px', paddingRight:  window.innerWidth< 800? "auto":'100px',padding: window.innerWidth> 800? "auto":"20px 35px 5px 35px" }}>{data[1].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[1].linkedInUrl} style={{ textDecoration: 'none', paddingLeft: '100px' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                        {window.innerWidth >800 &&<div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                           <img src={data[1].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="goutham" />
                        </div>}
                    </div>
                    {/* Item-3 */}
                    <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", marginTop: '100px' }}>
                        <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[2].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="jayanth" />
                        </div>
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems:  window.innerWidth >800 ?'flex-start':'center' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px' }}>{data[2].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', color: 'red' }}>{data[2].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', paddingRight:  window.innerWidth< 800? "auto":'120px',padding: window.innerWidth> 800? "auto":"20px 35px 5px 35px" }}>{data[2].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[2].linkedInUrl} style={{ textDecoration: 'none' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                    </div>
                    {/* Item-4 */}
                    <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", marginTop: '100px' }}>
                        {window.innerWidth <800 && <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[3].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="javed" />
                        </div>}
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems:  window.innerWidth >800 ?'flex-start':'center' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px', paddingLeft: window.innerWidth >800 ?'100px':"auto" }}>{data[3].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', paddingLeft: window.innerWidth >800 ?'100px':"auto", color: 'red' }}>{data[3].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px',padding: window.innerWidth>800? "auto":"25px 30px 5px 30px",paddingLeft: window.innerWidth >800 ?'100px':"auto", paddingRight:  window.innerWidth >800 ?'100px':"auto" }}>{data[3].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[3].linkedInUrl} style={{ textDecoration: 'none', paddingLeft: window.innerWidth >800 ?'100px':"auto" }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                        {window.innerWidth >800 && <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[3].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="javed" />
                        </div>}
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection:  window.innerWidth< 800 ?"column":"row", marginTop: '100px' }}>
                        <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[4].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="Kanad" />
                        </div>
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems:  window.innerWidth >800 ?'flex-start':'center' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px' }}>{data[4].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', color: 'red' }}>{data[4].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', paddingRight:  window.innerWidth< 800? "auto":'120px',padding: window.innerWidth> 800? "auto":"20px 35px 5px 35px" }}>{data[4].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[4].linkedInUrl} style={{ textDecoration: 'none' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                    </div>                   
                    {/* Item-5 */}
                    {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '100px' }}>
                        <div style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={data[4].imageUrl} style={{ height: '320px', width: '320px', objectFit: 'cover' }} alt="jocelyn" />
                        </div>
                        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                            <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '24px' }}>{data[4].name}</Typography>
                            <Typography fontWeight="Bold" variant="body2" style={{ fontSize: '14px', color: 'red' }}>{data[4].designation}</Typography>
                            <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '14px', paddingTop: '10px', paddingRight: '120px' }}>{data[4].description}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                <a href={data[4].linkedInUrl} style={{ textDecoration: 'none' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                            </div>
                        </div>
                    </div> */}
                    {/*
                        data.map((item, index) =>
                            <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: '140px', width: '140px', borderRadius: '140px' }}>
                                    <img src={item.imageUrl} style={{ height: '140px', width: '140px', borderRadius: '140px', objectFit: 'cover' }} />
                                </div>
                                <Typography fontWeight="Bold" variant="h1" style={{ fontSize: '20px', paddingTop: '10px' }}>{item.name}</Typography>
                                <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px' }}>{item.designation}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <a href={item.linkedInUrl} style={{ textDecoration: 'none' }}><img src='/images/about/linkedin-icon.png' style={{ paddingRight: '0px' }} /></a>
                                </div>
                            </div>
                        )
                    */}
                </div>
            </div>
        </Layout>
    );
}