import Typography from '@mui/material/Typography';

export default function ListContent() {
    return (
        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}>
            {/* Item-1 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column'  }}>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>Express to create a better world</Typography>
                    <hr style={{ width: '100%', marginTop: '10px', marginLeft: '120px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>Contribute in building a better community</Typography>
                </div>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Right.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon right"
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    />
                    <img src="/images/features/Feature-1.png"
                        width={window.innerWidth * 0.16}
                        height={window.innerHeight * 0.62}
                        alt="feature-1"
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                </div>
            </div>
            {/* Item-2 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column'  }}>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Left.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon left"
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                    />
                    <video src='/videos/features/k_reach.mp4' width={window.innerWidth * 0.16} height={window.innerHeight * 0.60} autoPlay loop muted
                        style={{ position: 'absolute', top: '10px', right: '-8px', zIndex: 2 }}
                    />
                    <img src={"/images/green-frame.png"} width={window.innerWidth * 0.15} height={window.innerHeight * 0.62} alt="green frame"
                        style={{ position: 'absolute', top: 0, right: '0', zIndex: 2 }}
                    />
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>Kitty Reach</Typography>
                    <hr style={{ width: '80%', marginTop: '10px', marginRight: '320px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>It is the count of number of kitty's reached. Measure of life's enabled.</Typography>
                </div>
            </div>
            {/* Item-3 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column'  }}>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>High Talks</Typography>
                    <hr style={{ width: '100%', marginTop: '10px', marginLeft: '120px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>Appreciate someone for their talent</Typography>
                </div>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Right.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon right"
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                    />
                    <video src='/videos/features/high_talk.mp4' width={window.innerWidth * 0.16} height={window.innerHeight * 0.60} autoPlay loop muted
                        style={{ position: 'absolute', top: '10px', left: '2px', zIndex: 2 }}
                    />
                    <img src={"/images/green-frame.png"} width={window.innerWidth * 0.15} height={window.innerHeight * 0.62} alt="green frame"
                        style={{ position: 'absolute', top: 0, left: '10px', zIndex: 2 }}
                    />
                </div>
            </div>
            {/* Item-4 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column'  }}>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Left.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon left"
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                    />
                    <video src='/videos/features/thunder_1.mp4' width={window.innerWidth * 0.16} height={window.innerHeight * 0.60} autoPlay loop muted
                        style={{ position: 'absolute', top: '10px', right: '-8px', zIndex: 2 }}
                    />
                    <img src={"/images/green-frame.png"} width={window.innerWidth * 0.15} height={window.innerHeight * 0.62} alt="green frame"
                        style={{ position: 'absolute', top: 0, right: '0', zIndex: 2 }}
                    />
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>Thunder</Typography>
                    <hr style={{ width: '80%', marginTop: '10px', marginRight: '320px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>Show people what you got</Typography>
                </div>
            </div>
            {/* Item-5 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: window.innerWidth > 800 ?'row':'column'  }}>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>Clubbies</Typography>
                    <hr style={{ width: '100%', marginTop: '10px', marginLeft: '120px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>Meet the like minded people around the world</Typography>
                </div>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Right.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon right"
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
                    />
                    <video src='/videos/features/clubbies.mp4' width={window.innerWidth * 0.16} height={window.innerHeight * 0.60} autoPlay loop muted
                        style={{ position: 'absolute', top: '10px', left: '2px', zIndex: 2 }}
                    />
                    <img src={"/images/green-frame.png"} width={window.innerWidth * 0.15} height={window.innerHeight * 0.62} alt="green frame"
                        style={{ position: 'absolute', top: 0, left: '10px', zIndex: 2 }}
                    />
                </div>
            </div>
            {/* Item-6 */}
            <div style={{ width: '100%', height: window.innerHeight * 0.62, display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1, position: 'relative' }}>
                    <img src="/images/features/Polygon-Left.png"
                        width={window.innerWidth * 0.24}
                        height={window.innerHeight * 0.62}
                        alt="polygon left"
                        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                    />
                    <video src='/videos/features/hifi.mp4' width={window.innerWidth * 0.16} height={window.innerHeight * 0.60} autoPlay loop muted
                        style={{ position: 'absolute', top: '10px', right: '-8px', zIndex: 2 }}
                    />
                    <img src={"/images/green-frame.png"} width={window.innerWidth * 0.15} height={window.innerHeight * 0.62} alt="green frame"
                        style={{ position: 'absolute', top: 0, right: '0', zIndex: 2 }}
                    />
                </div>
                <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                    <Typography fontWeight="SemiBold" variant="h1" style={{ fontSize: '28px', paddingLeft: '120px' }}>Hifi</Typography>
                    <hr style={{ width: '80%', marginTop: '10px', marginRight: '320px' }} />
                    <Typography fontWeight="Normal" variant="body2" style={{ fontSize: '12px', paddingTop: '10px', paddingLeft: '120px' }}>Hi-fi to start a conversation</Typography>
                </div>
            </div>
        </div>
    );
}